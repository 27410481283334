import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FeatureFlagsModule } from 'mojo-feature-flags';

import { ScoreLabelModule } from '@shared/score-label/score-label.module';

import { AlertModule } from './alert';
import { SharedApplyFormModule } from './apply-form/shared-apply-form.module';
import { ExpandableCardModule } from './expandable-card/expandable-card.module';
import { LoaderModule } from './loader/loader.module';
import { NgbDateCustomParserFormatter } from './ngb-date-formatter';
import { NoContentComponent } from './no-content/no-content.component';
import { PaginationModule } from './pagination';

export const sharedModules = [
  AlertModule,
  CommonModule,
  LoaderModule,
  PaginationModule,
  ExpandableCardModule,
  RouterModule,
  FeatureFlagsModule,
  SharedApplyFormModule,
  ScoreLabelModule,
];

const components = [
  NoContentComponent,
];

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    ...sharedModules,
  ],
  exports: [
    ...sharedModules,
    ...components,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: NgbDateParserFormatter,
          useClass: NgbDateCustomParserFormatter,
        },
      ],
    };
  }
}
