import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { toTenantUrl } from '@core/auth/path.utils';
import { environment } from '@env/environment';
import { removeEmpty } from '@shared/utils';

export interface ApiRequest<T> {
  body?: {};
  id?: number | string;
  params?: T;
  headers?: HttpHeaders;
  responseType?: any;
  url?: string;
}

export interface ApiParams {
  page?: number;
  offset?: number;
  size?: number;
  sort?: string;
  'sort.dir'?: SortDir;
  name?: string;
  email?: string;
  password?: string;
  candidateJobIndexSize?: number;
  candidateJobIndexOffset?: number;
  download?: string;
  allJobs?: boolean;
  useJobIdsFromFilter?: boolean;
}

export enum SortDir {
  DESC = 'desc',
  ASC = 'asc',
}

@Injectable({ providedIn: 'root' })
export class ApiService<T> {
  config = environment; // TODO: remove when moved to common-lib
  baseUrl = (this.config && `${toTenantUrl(this.config.BASE_URI)}/${this.url}`) || '';
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    protected http: HttpClient,
    @Inject(String) private readonly url: string
  ) { }

  convertOptions(
    options: Partial<ApiRequest<ApiParams>>
  ): ApiRequest<HttpParams> {
    const params = new HttpParams({ fromObject: removeEmpty(options.params) });

    return { ...options, params, headers: this.headers };
  }

  get<R = T>({
    id,
    url,
    ...options
  }: Partial<ApiRequest<ApiParams>> = {}): Observable<R> {
    return this.http
      .get<R>(
        this.buildUrl({ id, url }),
        this.convertOptions(options),
      )
      .pipe(catchError(this.handleError));
  }

  post<R = T>({
    id,
    url,
    body,
    ...options
  }: Partial<ApiRequest<ApiParams>>): Observable<R> {
    return this.http
      .post<R>(
        this.buildUrl({ id, url }),
        JSON.stringify(body),
        {
          ...this.convertOptions(options),
        }
      )
      .pipe(catchError(this.handleError));
  }

  put<R = T>({
    id,
    url,
    body,
    ...options
  }: Partial<ApiRequest<ApiParams>>): Observable<R> {
    return this.http
      .put<R>(
        this.buildUrl({ id, url }),
        JSON.stringify(body),
        this.convertOptions(options)
      )
      .pipe(catchError(this.handleError));
  }

  patch<R = T>({
    id,
    url,
    body,
    ...options
  }: Partial<ApiRequest<ApiParams>>): Observable<R> {
    return this.http
      .patch<R>(
        this.buildUrl({ id, url }),
        JSON.stringify(body),
        this.convertOptions(options)
      )
      .pipe(catchError(this.handleError));
  }

  delete<R = T>({ id, url }: Partial<ApiRequest<ApiParams>>): Observable<R> {
    return this.http
      .delete<R>(this.buildUrl({ id, url }))
      .pipe(catchError(this.handleError));
  }

  handleError(error: Response): Observable<never> {
    return throwError(error);
  }

  private buildUrl({ id, url = '' }: Partial<ApiRequest<ApiParams>>): string {
    return `${this.baseUrl}${url}${id || ''}`;
  }
}
