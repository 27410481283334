import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class IsMobileViewService {
  constructor(
    private readonly breakpointObserver: BreakpointObserver,
  ) {
  }

  sm = '36rem';

  isMobileView$(): Observable<boolean> {
    return this.breakpointObserver.observe(
      `(max-width: ${this.sm})`)
    .pipe(
      map(({matches}) => matches),
      shareReplay(1)
    );
  }
}
