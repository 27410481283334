import { createReducer, on } from '@ngrx/store';

import { UploadCandidateStatuses } from '@modules/jobs/models/upload-candidate-status';
import { Attachment } from '@shared/models/attachment';
import { Candidate } from '@shared/models/candidate';
import { Matches } from '@shared/models/matches';
import { Pagination } from '@shared/pagination';

import * as JobsPageActions from '../jobs-page.actions';

export interface JobsPageDetailsState {
  selectedId: number;
  parsing: {
    candidateId: number;
    candidate?: Candidate;
    matches?: Pagination<Matches>;
    status: string;
    attachment?: Attachment;
  };
  resumeSent?: {
    applyScores: {
      jobId: number;
      percentile: number;
      candidateId: number;
    }[];
    resumeSentFromMain: boolean;
    numberOfMatches: number;
  };
  candidateName?: string;
  confettiStatus?: boolean;
}

export const jobsPageDetailsInitialState: JobsPageDetailsState = {
  selectedId: 0,
  parsing: {
    candidateId: null,
    candidate: null,
    matches: null,
    status: null,
    attachment: null
  },
  resumeSent: {
    applyScores: [null],
    resumeSentFromMain: null,
    numberOfMatches: null
  },
  candidateName: null,
  confettiStatus: false,
};

export const jobsPageDetailsReducer = createReducer(
  jobsPageDetailsInitialState,
  on(JobsPageActions.jobsSelect, (state, { id }) => ({
    ...state,
    selectedId: id,
  })),
  on(JobsPageActions.jobsUnSelect, (state) => ({
    ...state,
    selectedId: null,
  })),
  on(JobsPageActions.jobsUploadResume, (state, { resume, jobId }) => ({
    ...state,
    parsing: {
      ...state.parsing,
      candidateId: null,
      status: UploadCandidateStatuses.Uploading
    }
  })),
  on(JobsPageActions.jobsUploadResumeSuccessForMatching, (state, { jobId, candidateId, attachment }) => (

    desesctructuration(state, attachment)

  )),
  on(JobsPageActions.jobsUploadResumeOnJobPageSuccess, (state, { attachment }) => (
    desesctructuration(state, attachment)
  )),
  on(JobsPageActions.jobsCreatCandidateForUploadResumeSuccess, (state, { candidateId }) => ({
    ...state,
    parsing: {
      ...state.parsing,
      candidateId,
    }
  })),
  on(JobsPageActions.matchesLoadCalculationSuccess, (state, { matches }) => ({
    ...state,
    parsing: {
      ...state.parsing,
      matches,
    }
  })),
  on(JobsPageActions.jobsAnalyzingFinishedSuccess, (state, { candidate }) => ({
    ...state,
    parsing: {
      ...state.parsing,
      candidate,
      status: UploadCandidateStatuses.MatchingFinished
    }
  })),
  on(JobsPageActions.resumeSentFromMain, (state, { resumeSentFromMain, numberOfMatches }) => ({
    ...state,
    resumeSent: {
      ...state.resumeSent,
      resumeSentFromMain,
      numberOfMatches
    },
  })),
  on(JobsPageActions.applyAnalyzingFinishedSuccess, (state, { candidateId, jobId, percentile }) => ({
    ...state,
    resumeSent: {
      applyScores: [...state.resumeSent.applyScores, {
        jobId,
        percentile,
        candidateId
      }],
      resumeSentFromMain: state.resumeSent.resumeSentFromMain,
      numberOfMatches: state.resumeSent.numberOfMatches
    },
  })),
  on(JobsPageActions.changeUploadingResumeStateBasedOnTimeout, (state, { newStatus }) => ({
    ...state,
    parsing: {
      ...state.parsing,
      status: state.parsing.status === UploadCandidateStatuses.MatchingFinished || state.parsing.status === UploadCandidateStatuses.Error ?
        state.parsing.status : newStatus,
    }
  })),
  on(JobsPageActions.candidateName, (state, { candidateName }) => ({
    ...state,
    candidateName,
  })),
  on(
    JobsPageActions.jobsResumeParsedFail,
    JobsPageActions.jobsUploadResumeFail,
    JobsPageActions.jobsAnalyzingFinishedFail,
    (state) => ({
      ...state,
      parsing: {
        candidateId: null,
        candidate: null,
        attachment: null,
        status: UploadCandidateStatuses.Error
      }
    })),
  on(JobsPageActions.setStateForConfettiAnimation, (state, { stateForConfettiAnimation }) => ({
    ...state,
    confettiStatus: stateForConfettiAnimation,
  })),
);



function desesctructuration(state, attachment) {
  return {
    ...state,
    parsing: {
      ...state.parsing,
      attachment
    }
  };
}
