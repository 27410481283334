import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { getJobsPageSelectedId } from '@modules/jobs/store/jobs-page.selectors';
import { Referral } from '@shared/models/referral';

import { CollectionsState, getCollectionsState } from '../collections.reducer';
import { referralsAdapter, ReferralsState} from './referrals.reducer';

export const getReferralsState = createSelector(
  getCollectionsState,
  (state: CollectionsState) => state?.referrals
);

const {
  selectEntities,
  selectAll,
} = referralsAdapter.getSelectors(getReferralsState);

export const getReferrals = selectEntities;

export const getSelectedReferralId = createSelector(
  getReferralsState,
  (state: ReferralsState) => state.selectedReferralId
);

export const getSelectedReferral = createSelector(
  getReferrals,
  getSelectedReferralId,
  (referrals: Dictionary<Referral>, id: number) => referrals[id]
);

export const getReferralById = createSelector(
  getReferrals,
  (referrals: Dictionary<Referral>, props) => {
    if (!!referrals && !!props.referralId) {
      return referrals[props.referralId];
    }

    return null;
  });

export const getReferralByJobId = createSelector(
  selectAll,
  (referrals: Referral[], props) => referrals.find((referral) => referral.jobId === props.jobId)
);

export const checkIfIsAlreadyReferred = createSelector(
  getReferrals,
  getJobsPageSelectedId,
  (referrals, selectedJobId) =>
     Object.values(referrals)
       .map((referral) => referral.jobId)
       .includes(selectedJobId)
);
