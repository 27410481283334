import { HttpClient } from '@angular/common/http';
import { Injectable, Optional, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JobBoardAttachmentTypeEnum } from '@core/job-board/job-board-attachment-type.enum';
import { Attachment } from '@shared/models/attachment';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class AttachmentApiService extends ApiService<Attachment> {
  constructor(
    http: HttpClient,
    @Inject(String) @Optional() url: string
  ) {
    super(http, `attachment/${url || ''}`);
  }

  getJobBoardAttachment(id: number, type: JobBoardAttachmentTypeEnum): Observable<string> {
    return this
      .get({ url: `${type}/${id}`})
      .pipe(map((attachment) => attachment.path));
  }
}
