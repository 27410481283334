import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@material/material.module';

import { JobIdLabelComponent } from './job-id-label.component';

const components = [
  JobIdLabelComponent,
];

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    CommonModule,
    MaterialModule,
  ],
  exports: [
    ...components,
  ]
})
export class JobIdLabelModule { }
