import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';

import { AlertModule } from '@shared/alert';

import { AuthGuardDirective } from './auth.directive';
import { AuthGuard } from './auth.guard';
import { AuthInterceptor } from './auth.interceptor';
import { AuthService } from './auth.service';

const components = [AuthGuardDirective];

function handleAuthentication(authService: AuthService) {
  return () => authService.handleAuthentication();
}

@NgModule({
  imports: [AlertModule],
  declarations: [...components],
  exports: [...components],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthGuard,
        AuthService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
          provide: APP_INITIALIZER,
          useFactory: handleAuthentication,
          deps: [AuthService],
          multi: true,
        },
      ]
    };
  }
}
