import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '@core/auth';

@Injectable({ providedIn: 'root'})
export class LogoutRedirectGuard implements CanActivate {

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
   if (localStorage.getItem('authorizeAfterLogout') === 'true') {
     this.authService.login();
   }  else {
      this.router.navigate([localStorage.getItem('redirectAfterLogout')]); // todo: use query params instead
   }

   return false;
  }

}