import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Service used to synchronize cypress effects with other sources of application stability changes.
 */
@Injectable()
export class CypressSynchronizationService {
  private readonly requestResolvedSubject$ = new BehaviorSubject<boolean>(true);

  open = 0;
  requestResolved$ = this.requestResolvedSubject$.asObservable();

  increaseOpenedRequests() {
    this.open++;
    this.requestResolvedSubject$.next(false);
  }

  decreaseOpenedRequests() {
    this.open--;
    this.requestResolvedSubject$.next(this.open === 0);
  }
}
