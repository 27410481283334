import { Component } from '@angular/core';

@Component({
  selector: 'mojo-loader',
  styleUrls: ['./loader.component.scss'],
  template: `
    <mat-spinner [diameter]="50" [strokeWidth]="4"></mat-spinner>
  `,
})
export class LoaderComponent {}
