import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ActionReducerMap, createReducer, on } from '@ngrx/store';

import { Matches } from '@shared/models/matches';
import { Pagination } from '@shared/pagination';

import {
  calculateApplicationsPercentilesDifferenceSuccess,
  clickExperienceBulb,
  loadExperienceBulbDatesSuccess,
  loadLastLoginDateFromLocalStorageSuccess,
  profilePageLoadCandidateAssociationsSuccess,
  profilePageLoadMatchesSuccess,
} from './profile-page.actions';

export const PROFILE_PAGE_TOKEN = 'profilePage';

export interface ProfileDataState {
  lastSeenDate: Date;
  clicked: boolean;
  lastLoginDate: Date;
}

export interface ProfilePageState {
  jobScores: Pagination;
  candidateAssociations: Pagination<Matches>;
  applicationsScoresCompared: ApplicationScoresDifferences;
  profileDataState: ProfileDataState;
}

export type ApplicationScoresDifferences = EntityState<Matches>

export const applicationScoresAdapter: EntityAdapter<Matches> = createEntityAdapter<Matches>();

export const initialState: ApplicationScoresDifferences = applicationScoresAdapter.getInitialState();


export const profilePageReducer: ActionReducerMap<ProfilePageState> = {
  jobScores: createReducer(
    null,
    on(profilePageLoadMatchesSuccess, (state, { jobScores }) => ({
      ...state,
      ...jobScores,
    }))
  ),
  candidateAssociations: createReducer(
    null,
    on(profilePageLoadCandidateAssociationsSuccess, (state, { jobScores }) => ({
      ...state,
      ...jobScores,
    }))
  ),
  profileDataState: createReducer(
    null,
    on(loadExperienceBulbDatesSuccess, (state, { lastSeenDate }) => ({
      ...state,
      lastSeenDate,
    })),
    on(clickExperienceBulb, (state) => ({
      ...state,
      clicked: true,
    })),
    on(loadLastLoginDateFromLocalStorageSuccess, (state, { lastLoginDate }) => ({
      ...state,
      lastLoginDate,
    })),
  ),
  applicationsScoresCompared: createReducer(
  initialState,
  on(calculateApplicationsPercentilesDifferenceSuccess, (state, { calculatedApplicationsScoresDifferences, jobScores }) => {
    const applicationsScoresTemp = JSON.parse(localStorage.getItem('applicationsScoresTemp'));
    const mergedObj = jobScores.concat(applicationsScoresTemp.filter( (s) => !jobScores.find( (t) => t.id === s.id)));
    localStorage.setItem('applicationsScoresTemp', JSON.stringify(mergedObj));

    return applicationScoresAdapter.upsertMany(calculatedApplicationsScoresDifferences, state);}
  )),
};
