import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { asyncScheduler, merge, Subscription, SchedulerLike, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, mapTo, share, take, tap } from 'rxjs/operators';

import { CypressSynchronizationService } from './cypress-synchronization.service';

interface CypressCommunicationInterface {
  /** true - after first signal of stabilization */
  cypressAppInitialized: boolean;
  /**
   * true - when application has stabilized and is ready for cypress e2e tests
   * false - when application is not stable and probably needs some time to stabilize
   *   this one actually may not stabilize in some cases ever again
   */
  cypressAppStable: boolean;
  /** true - when user was authenticated in application */
  cypressLoggedInReady: boolean;
}

declare const window: CypressCommunicationInterface;

/**
 * Utility class allowing to use of async operation in observable without breaking app stability.
 */
class LeaveZoneSchduler {
  constructor(
    private readonly zone: NgZone,
    private readonly scheduler: SchedulerLike,
  ) { }

  schedule(...args: any[]): Subscription {
    return this.zone.runOutsideAngular(() =>
      this.scheduler.schedule.apply(this.scheduler, args)
    );
  }
}

/**
 * Utility class allowing to use of async operation in observable without breaking app stability.
 */
class EnterZoneScheduler {
  constructor(
    private readonly zone: NgZone,
    private readonly scheduler: SchedulerLike,
  ) { }

  schedule(...args: any[]): Subscription {
    return this.zone.run(() =>
      this.scheduler.schedule.apply(this.scheduler, args)
    );
  }
}

/**
 * You can use this helper function to create scheduler that will run outside ngZone.
 *
 * @see enterZone
 * @example
 * import { asyncScheduler } from 'rxjs/internal/scheduler/async';
 *
 * timer(500, leaveZone(ngZone, asyncScheduler)),
 */
export function leaveZone(zone: NgZone, scheduler: SchedulerLike): SchedulerLike {
  return new LeaveZoneSchduler(zone, scheduler) as any;
}

/**
 * You can use this helper function to create scheduler that will get back into ngZone.
 *
 * @see leaveZone
 * @example
 * import { asyncScheduler } from 'rxjs/internal/scheduler/async';
 *
 * timer(500, leaveZone(ngZone, asyncScheduler)).pipe(observeOn(enterZone(ngZone, asyncScheduler))),
 */
export function enterZone(zone: NgZone, scheduler: SchedulerLike): SchedulerLike {
  return new EnterZoneScheduler(zone, scheduler) as any;
}

/**
 * Manages our app interface for comunicating with Cypress.
 * Stabilization signals tested comming from ApplicationRef and NgZone second gives better results.
 */
@Injectable()
export class CypressEffects {

  /**
   * if something should cause stability change signal for testing it can be added here:
   */
  stabilizationState$ = combineLatest([
    this.synchronizationService.requestResolved$,
    merge(
      // when used with ngrx there is always ongoing macrotask and internal state for zone is not stable
      // you cannot use isStable value from ng zone as it will always false
      // but you can use onStable, onUnstable to have temporary stabilization signals
      this.zone.onStable.pipe(mapTo(true)),
      this.zone.onUnstable.pipe(mapTo(false)),
    ),
  ])
    .pipe(
      map(([requestResolved, zoneStable]) => requestResolved && zoneStable),
      // sometimes stabilization signal come in big bursts we care only for the last value those burst return
      debounceTime(50, leaveZone(this.zone, asyncScheduler)),
      distinctUntilChanged(),
      share(),
    );

  cypressAppInitialization$ = createEffect(() => this.stabilizationState$.pipe(
    filter((isStable) => isStable),
    take(1),
    tap(() => {
      if (window) {
        window.cypressAppInitialized = true;
      }
    }),
  ), {
    dispatch: false,
  });

  cypressAppStabilization$ = createEffect(() => this.stabilizationState$.pipe(
    tap((isStable) => {
      if (window) {
        window.cypressAppStable = isStable;
      }
    }),
  ), {
    dispatch: false,
  });

  // TODO: add after implementing authentication
  // cypressLoggedInConfirmation$ = createEffect(() => this.actions$.pipe(
  //   ofType(UsersActionTypes.LoggedIn),
  //   tap(() => {
  //     if (window) {
  //       window.cypressLoggedInReady = true;
  //     }
  //   }),
  // ), {
  //   dispatch: false,
  // });

  constructor(
    private readonly actions$: Actions,
    private readonly zone: NgZone,
    private readonly synchronizationService: CypressSynchronizationService,
  ) {
  }
}
