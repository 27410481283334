import { createAction, props } from '@ngrx/store';

import { ApiParams } from '@core/api';
import { Job } from '@shared/models';
import { Attachment } from '@shared/models/attachment';
import { Candidate } from '@shared/models/candidate';
import { Matches } from '@shared/models/matches';
import { Pagination } from '@shared/pagination';

import { JobPageFilters } from './jobs-page.reducer';

/**
 * This is local module that main task is to handle pagination
 * and module specific actions. Should wrap collection/jobs
 */

export const jobsLoadJobsPage = createAction(
  '[Jobs Page] Load Page',
  props<{ params?: ApiParams }>()
);

export const jobsLoadJobsPageSuccess = createAction(
  '[Jobs Page] Load Page Success',
  props<{ page: Pagination<Job> }>()
);

export const jobsSelect = createAction(
  '[Jobs Page] Select Page',
  props<{ id: number }>()
);

export const jobsUnSelect = createAction(
  '[Jobs Page] Un-Select Page',
);

export const jobsPageFiltersSet = createAction(
  '[Jobs Page Filters] Set',
  props<{ payload?: Partial<JobPageFilters> }>()
);

export const resetFiltersWithoutDispatch = createAction(
  '[Jobs Page Filters] Reset Filters Without Dispatch',
  props<{ payload?: Partial<JobPageFilters> }>()
);

export const jobsPageUploadFail = createAction(
  '[Jobs Page] Upload Resume Fail',
  props<{ error: any }>()
);

export const jobsPageUploadFileTooBigFail = createAction(
  '[Jobs Page] Upload File Too Big Fail',
  props<{ maxFileSize: number }>()
);

export const jobsLoadCites = createAction(
  '[Jobs Page] Get cities',
  props<{ canonicalName: string }>()
);

export const jobsLoadCitesSuccess = createAction(
  '[Jobs Page] Get cities Success',
  props<{ cities: string[] }>()
);

export const jobsLoadDepartments = createAction(
  '[Jobs Page] Get Departments',
  props<{ canonicalName: string }>()
);

export const jobsLoadDepartmentsSuccess = createAction(
  '[Jobs Page] Get Departments Success',
  props<{ departments: string[] }>()
);

export const jobsUploadResume = createAction(
  '[Jobs Page] Upload Resume',
  props<{ resume: File; jobId?: number }>()
);

export const jobsCreatCandidateForUploadResumeSuccess = createAction(
  '[Jobs Page] Create Candidate For Upload Resume Success',
  props<{ candidateId: number; resume: File; jobId?: number }>()
);

export const jobsCreatCandidateForUploadResumeFail = createAction(
  '[Jobs Page] Create Candidate For Upload Resume Fail',
  props<{ error: any }>()
);

export const jobsUploadResumeSuccess = createAction(
  '[Jobs Page] Upload Resume Success',
  props<{ attachment: Attachment }>()
);

export const jobsUploadResumeSuccessForMatching = createAction(
  '[Jobs Page] Upload Resume Success For Matching',
  props<{ jobId: number; candidateId: number; attachment: Attachment }>()
);

export const jobsUploadResumeFail = createAction(
  '[Jobs Page] Upload Resume Fail',
  props<{ error: any }>()
);

export const jobsResumeParsedFail = createAction(
  '[Jobs Page] Resume Parsed Fail',
  props<{ candidateId: number }>()
);

export const jobsAnalyzingFinishedSuccess = createAction(
  '[Jobs Page] Analyzing Finished Success',
  props<{ candidate: Candidate }>()
);

export const jobsAnalyzingFinishedFail = createAction(
  '[Jobs Page] Analyzing Finished Fail',
  props<{ candidate: Candidate }>()
);

export const matchesLoadCalculation = createAction(
  '[Jobs Page] Matches Load Calculation',
  props<{ candidateId: number; offset: number }>()
);

export const matchesLoadCalculationSuccess = createAction(
  '[Jobs Page] Matches Load Calculation Success',
  props<{ matches: Pagination<Matches> }>()
);

export const matchesLoadCalculationFail = createAction(
  '[Jobs Page] Matches Load Calculation Fail',
  props<{ error: any }>()
);

export const applyAnalyzingFinishedSuccess = createAction(
  '[Jobs Page] Apply By Yourself Analyzing Success',
  props<{ jobId: number; candidateId: number; percentile: number}>()
);

export const applyAnalyzingFinishedFail = createAction(
  '[Jobs Page] Apply By Yourself Analyzing Fail',
  props<{ error: any}>()
);

export const jobsUploadResumeOnJobPageSuccess = createAction(
  '[Jobs Page] Jobs Upload Resume On Job Page Success',
  props<{ attachment: Attachment}>()
);

export const candidateName = createAction(
  '[Jobs Page] Set Candidate Name',
  props<{candidateName: string}>()
);

export const resumeSentFromMain = createAction(
  '[Jobs Page] Resume Sent From Main',
  props<{resumeSentFromMain: boolean; numberOfMatches?: number}>()
);

export const setStateForConfettiAnimation = createAction(
  '[Jobs Page] Set State for Confetti Animation',
  props<{stateForConfettiAnimation: boolean}>()
);

export const isJobAssociatedWithLoggedCandidate = createAction(
  '[Jobs Page] Is Job Associated With Logged Candidate',
  props<{jobId: number}>()
);

export const isJobAssociatedWithLoggedCandidateSuccess = createAction(
  '[Jobs Page] Is Job Associated With Logged Candidate Success',
  props<{isJobAssociated: boolean}>()
);

export const setNoMatchesFlag = createAction(
  '[Jobs Page] Set No Matches Flag',
);

export const changeUploadingResumeStateBasedOnTimeout = createAction(
  '[Jobs Page] Change Uploading Resume State If Uploading Takes To Much Time',
  props<{ newStatus: string }>()
);
