import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, map } from 'rxjs/operators';

import { AlertComponent } from './components/alert/alert.component';
import { showModalAlert, showModalAlertSuccess } from './modal.actions';

@Injectable()
export class ModalEffects {

  show$ = createEffect(() => this.actions$.pipe(
    ofType(showModalAlert),
    tap(({  }) => {
      this.modalService.open(AlertComponent, {
        centered: true,
        backdrop: 'static',
      });
    }),
    map((state) => showModalAlertSuccess())
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly modalService: NgbModal,
  ) { }
}
