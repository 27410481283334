import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mojo-no-content',
  template: `
    <p class="text-center text-muted">{{ message }}</p>
  `,
  styleUrls: ['./no-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoContentComponent {
  @Input()
  message = 'No content';
}
