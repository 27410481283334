import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import * as ReferralsActions from '@collections/referrals/referrals.actions';
import { ReferralApiService } from '@core/api/referral-api.service';
import { getSelectedJobBoardCanonicalName, getSelectedJobBoardId } from '@core/job-board/job-board.selectors';
import { Referral } from '@shared/models/referral';

@Injectable()
export class ReferralsEffects {
  getReferral$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReferralsActions.getReferral),
      switchMap(({ id }) =>
        this.referralService.getReferral(id)
          .pipe(
            map((referral) => ReferralsActions.getReferralSuccess({ referral })),
            catchError((error) => of(ReferralsActions.getReferralFail({ error })))
          )
      )
    )
  );

  createReferral$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReferralsActions.createReferral),
      withLatestFrom(this.store.select(getSelectedJobBoardId)),
      switchMap(([{ referralForm }, jobBoardId]) => this.referralService.createReferral( { ...referralForm, jobBoardId })
        .pipe(
          map((referralCreated: Referral) =>
            ReferralsActions.createReferralSuccess({ referral: referralCreated })),
          catchError((error) =>
            of(ReferralsActions.createReferralFail({ error })))
        )
      )
    ));

  createReferralSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReferralsActions.createReferralSuccess),
      withLatestFrom(this.store.select(getSelectedJobBoardCanonicalName)),
      mergeMap(([{ referral }, canonicalName]) =>
        !!referral.jobId ? this.router.navigate(['/' + canonicalName + '/' + referral.jobId + '/referral/thank-you']) :
                           this.router.navigate(['/' + canonicalName + '/no-matches-referral/thank-you'])
      ),
    ), { dispatch: false });

  constructor(
    private readonly actions$: Actions,
    private readonly referralService: ReferralApiService,
    private readonly router: Router,
    private readonly store: Store,
  ) {
  }
}
