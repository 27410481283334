import { ElementRef, Directive, OnInit } from '@angular/core';

import { AuthGuard } from './auth.guard';

@Directive({
  selector: '[mojoAuthGuard]',
})
export class AuthGuardDirective implements OnInit {

  constructor(
    private readonly el: ElementRef,
    private readonly authGuard: AuthGuard
  ) { }

  ngOnInit() {
    if (!this.authGuard.canAccess()) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
