import { createAction, props } from '@ngrx/store';

import { ModalAlert } from '@shared/models/modal';


export const showModalAlert = createAction(
  '[Modal] Show',
  props<{ currentState: Partial<ModalAlert> }>()
);

export const showModalAlertSuccess = createAction(
  '[Modal] Show Success',
);

export const confirmModal = createAction(
  '[Modal] Confirm clicked',
  props<{ content?: string; redirectUrl?: string }>()
);

export const cancelModal = createAction(
  '[Modal] Cancel clicked',
);

export const resetModal = createAction(
  '[Modal] Reset',
);
