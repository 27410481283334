import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as AttachmentsActions from '@collections/attachments/attachments.actions';
import * as CandidatesActions from '@collections/candidates/candidates.actions';
import { PublicExperienceApiService } from '@core/api/public-experience-api.service';
import { ApplyModuleUtils } from '@shared/models/apply-module-object';

@Injectable()
export class AttachmentsEffects {
  createAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentsActions.createAttachment),
      switchMap(({ attachment, resume }) => this.publicCandidateApiService.createAttachment(attachment, resume)
        .pipe(
          map((attachmentCreated) =>
            AttachmentsActions.createAttachmentSuccess({ attachment: attachmentCreated })),
          catchError((error) =>
            of(AttachmentsActions.createAttachmentFail({ error })))
        )
      )
    ));

  uploadResume$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentsActions.uploadApplyModuleResume),
      switchMap(({ applyModuleObject }) =>
        this.publicCandidateApiService
          .createAttachment(
            ApplyModuleUtils.getAttachmentObject(applyModuleObject.candidateId),
            applyModuleObject.resumeFile,
            applyModuleObject.referredBy
          )
          .pipe(
            map(() => AttachmentsActions.uploadApplyModuleResumeSuccess({ applyModuleObject })),
            catchError((error) => of(AttachmentsActions.uploadApplyModuleResumeFail({ error })))
          )
      )
    ));

  uploadResumeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentsActions.uploadApplyModuleResumeSuccess),
      map(({ applyModuleObject }) =>
        CandidatesActions.upsertCandidateByApplyModuleSuccess({ applyModuleObject })
      )
    ));

  constructor(
    private readonly actions$: Actions,
    private readonly publicCandidateApiService: PublicExperienceApiService,
  ) {
  }
}
