import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { MojoErrorFieldsModule } from 'mojo-error-fields';
import { MojoFileUploadModule } from 'mojo-file-upload';
import { MojoPhoneInputModule } from 'mojo-phone-input';

import { MaterialModule } from '@material/material.module';
import { FormHeaderModule } from '@shared/form-header/form-header.module';
import { LocationInputModule } from '@shared/location-input/location-input.module';
import { TermsAndConditionsModalModule } from '@shared/terms-and-conditions-modal/terms-and-conditions-modal.module';

import { JobIdLabelModule } from '../job-id-label/job-id-label.module';
import { LocationLabelModule } from '../location-label/location-label.module';
import { SharedApplyFormComponent } from './shared-apply-form.component';

const components = [
  SharedApplyFormComponent,
];

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    MojoFileUploadModule,
    MojoErrorFieldsModule,
    LocationLabelModule,
    JobIdLabelModule,
    RouterModule,
    LocationInputModule,
    MojoPhoneInputModule,
    FormHeaderModule,
    MatProgressSpinnerModule,
    TermsAndConditionsModalModule,
  ],
  exports: [
    ...components,
  ]
})
export class SharedApplyFormModule { }
