import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State, WebsocketState } from './websocket.reducer';

const getWebsocketState = createFeatureSelector<WebsocketState>(
  'websocket',
);

export const isWebSocketConnected = createSelector(
  getWebsocketState,
  (state: WebsocketState) => state.state === State.CONNECTED
);
