import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

import { PageLayoutModule } from '@app/layout/page-layout/page-layout.module';

import { PageNotFoundComponent } from './page-not-found.component';

const components = [
  PageNotFoundComponent,
];

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    CommonModule,
    PageLayoutModule,
    MatIconModule,
  ],
  exports: [
    ...components,
  ]
})
export class PageNotFoundModule { }
