import { Component, Input } from '@angular/core';

@Component({
  selector: 'mojo-job-id-label',
  templateUrl: './job-id-label.component.html',
  styleUrls: ['./job-id-label.component.scss']
})
export class JobIdLabelComponent {
  @Input() jobId: number;
}
