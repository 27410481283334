import { config } from './config';

export const environment = {
  envName: 'prod',
  production: true,
  anonymizationFlagId: 'd651abb7-1a68-4f2a-a6d5-4f0040cf9db3',
  BASE_URI: 'https://TENANT.jobs.mojohire.ai/api/private',
  AUTH0_CLIENT_ID: 'u3wh1JVaeWAFhh3uMfj124rnOr2IHlVy',
  AUTH0_DOMAIN: 'mojo-prod.auth0.com',
  AUTH0_AUDIENCE: 'https://irp.prod.mojorank.com/',
  AUTH0_REDIRECT_UI: 'https://TENANT.jobs.mojohire.ai/loggedIn',
  AUTH0_LOGOUT_URL: 'https://TENANT.jobs.mojohire.ai/logout',
  ADMIN_MAIL: '',
  USER_METADATA: 'https://mojorank.com/metadata',
  DEFAULT_DEV_TENANT: config.defaultTenant
};
