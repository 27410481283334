import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Elevations } from 'mojo-card';

@Component({
  selector: 'mojo-expandable-card',
  templateUrl: './expandable-card.component.html',
  styleUrls: ['./expandable-card.component.scss'],
})
export class ExpandableCardComponent implements AfterViewInit {
  @Input() elevation: Elevations = Elevations.first;

  @Input() heightOnCollapsed = '32rem';

  @Input() isExpanded = false;

  @ViewChild('content', { static: false }) content: ElementRef;

  ngAfterViewInit(): void {
    this.setContentHeight();
  }

  expandOrCollapse(): void {
    this.isExpanded = !this.isExpanded;
    this.setContentHeight();
  }

  setContentHeight() {
    this.content.nativeElement.style.height = !this.isExpanded
      ? this.heightOnCollapsed
      : '100%';
  }
}
