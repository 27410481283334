import { Component } from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {getRouterData} from '@core/core.reducer';

@Component({
  selector: 'mojo-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(private readonly store: Store<{}>) {}

  get displayLayout(): Observable<boolean>{
    return this.store.select(getRouterData)
      .pipe(
        map((data) =>
          (!!data && data.displayLayout !== undefined)? data.displayLayout: true)
    );
  }
}
