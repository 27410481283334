import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LayoutHeaderModule } from '@app/layout/header/header.module';
import { coreConfig, CORE_CONFIG } from '@core/core.config';
import { CoreModule } from '@core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutFooterModule } from './layout/footer/footer.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    LayoutHeaderModule,
    LayoutFooterModule
  ],
  providers: [
    {
      provide: CORE_CONFIG,
      useValue: coreConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
