import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  /**
   * Save unique value in array in local storage
   * @param key - local storage key
   * @param value - element to save in array
   */
  addUniqueToArray(key: string, value: number) {
    const localSet = new Set(
      this.arraySafeLoad(key)
    );
    localSet.add(value);
    const updatedArray = Array.from(localSet);
    localStorage.setItem(key, JSON.stringify(updatedArray));
  }

  /**
   * Load array from local storage
   * @param key - local storage key
   * @returns array or empty array if LC key is empty
   */
  arraySafeLoad(key: string): number[] {
    const load = localStorage.getItem(key);

    return !load
      ? []
      : JSON.parse(load);
  }
}
