import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@core/auth';
import { DefaultBoardGuard } from '@modules/jobs/services/default-board.guard';
import { JobsBoardGuard } from '@modules/jobs/services/job-board.guard';
import { JobsBoardResolver } from '@modules/jobs/services/job-board.resolver';
import { LocalStateLoaderGuard } from '@modules/jobs/services/local-state-loader.guard';
import { ReferralGuard } from '@modules/jobs/services/referral.guard';
import { ProfilePageResolver } from '@modules/profile-page/profile-page.resolver';
import { PageNotFoundComponent } from '@shared/page-not-found/page-not-found.component';
import { PageNotFoundModule } from '@shared/page-not-found/page-not-found.module';

import { LogoutRedirectGuard } from './guards/logout-redirect.guard';

export const appRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '404',
        canActivate: [ DefaultBoardGuard ],
        component: PageNotFoundComponent,
        pathMatch: 'full',
        data: { displayLayout: false }
      },
      {
        path: '',
        redirectTo: '404',
        pathMatch: 'full',
      },
      {
        path: 'loggedIn',
        redirectTo: localStorage.getItem('redirect'),
        pathMatch: 'full',
      },
      {
        path: 'logout',
        canActivate: [LogoutRedirectGuard],
        pathMatch: 'full',
        children: [],
      },
      {
        path: 'default',
        redirectTo: '/404',
        pathMatch: 'full',
      },
      {
        path: 'default',
        children: [
          { path: '**', redirectTo: '/404' },
        ]
      },
      {
        path: 'settings/email-notifications',
        redirectTo: `/${localStorage.getItem('redirect')}/settings/email-notifications`
      },
      {
        path: ':jobBoardCanonicalName',
        canActivate: [
          JobsBoardGuard,
          ReferralGuard,
          LocalStateLoaderGuard,
        ],
        resolve: [
          JobsBoardResolver,
        ],
        data: {
          jobBoardMissingRedirect: '/404',
          notFoundRedirect: '/404',
        },
        children: [
          {
            path: 'profile',
            resolve: [
              ProfilePageResolver,
            ],
            canLoad: [
              AuthGuard,
              ProfilePageResolver,
            ],
            loadChildren: () =>
              import('./modules/profile-page/profile-page.module')
                .then(
                  (module) => module.ProfilePageModule,
                ),
          },
          {
            path: 'settings',
            canLoad: [
              AuthGuard,
            ],
            loadChildren: () =>
              import('./modules/settings-page/settings-page.module')
                .then(
                  (module) => module.SettingsPageModule,
                ),
          },
          {
            path: 'password',
            loadChildren: () =>
              import('./modules/set-password/set-password.module')
                .then(
                  (module) => module.SetPasswordModule
                ),
          },
          {
            path: 'referral-history',
            loadChildren: () =>
              import('./modules/referrals/referrals.module')
                .then(
                  (module) => module.ReferralsModule
                ),
          },
          {
            path: '',
            pathMatch: 'prefix',
            loadChildren: () =>
              import('./modules/jobs/jobs.module')
                .then(
                  (module) => module.JobsModule
                ),
          },
          {
            path: '**',
            redirectTo: '/default',
          },
        ]
      },
      { path: '**', redirectTo: '/default', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [
    PageNotFoundModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
      enableTracing: false,
      relativeLinkResolution: 'legacy'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
