import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mojo-score-label',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './score-label.component.html',
  styleUrls: ['./score-label.component.scss']
})
export class ScoreLabelComponent {
  @Input() btnLabel: string;
}
