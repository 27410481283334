import { LayoutModule } from '@angular/cdk/layout';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MojoAvatarModule } from 'mojo-avatar';
import { FeatureFlagsModule } from 'mojo-feature-flags';
import { MojoMonitoringModule } from 'mojo-monitoring';
import { MojoNavMenuModule, AvatarDownloadServiceToken, AvatarConfigServiceToken } from 'mojo-nav-menu';

import { CollectionsModule } from '@app/collections';
import { ProfileApiService } from '@core/api/profile-api.service';
import { toTenantUrl } from '@core/auth/path.utils';
import { LicenseConfigurationModule } from '@core/license-configuration/license-configuration.module';
import { CustomRouterStateSerializer } from '@core/router-state-serializer';
import { environment } from '@env/environment';
import { PROFILE_PAGE_TOKEN, profilePageReducer } from '@modules/profile-page/store/profile-page.reducer';
import { ModalModule } from '@shared/modal/modal.module';
import { SharedModule } from '@shared/shared.module';

import { AuthModule } from './auth/auth.module';
import { coreReducers } from './core.reducer';
import { CypressModule } from './cypress/cypress.module';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { UserEffects } from './user/user.effects';
import { WebsocketModule } from './websocket/websocket.module';

@NgModule({
  exports: [
    SharedModule,
  ],
  imports: [
    CypressModule.forRoot(),
    AuthModule.forRoot(),
    CollectionsModule,
    ModalModule,
    EffectsModule.forRoot([UserEffects]),
    StoreModule.forFeature(PROFILE_PAGE_TOKEN, profilePageReducer),
    HttpClientModule,
    SharedModule.forRoot(),
    StoreModule.forRoot(coreReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      name: 'Candidate Experience',
      logOnly: environment.production,
    }),
    FeatureFlagsModule.forRoot(toTenantUrl(environment.BASE_URI)),
    WebsocketModule,
    LayoutModule,
    LicenseConfigurationModule,
    MojoMonitoringModule.forRoot({
      monitorNavigation: true,
      monitorApplicationStabilization: true,
      monitorErrors: true,
    }),
    MojoNavMenuModule.forRoot(AvatarDownloadServiceToken, AvatarConfigServiceToken),
    MojoAvatarModule.forRoot(AvatarDownloadServiceToken, AvatarConfigServiceToken),
  ],
  providers: [
    CanDeactivateGuard,
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: AvatarDownloadServiceToken,
      useExisting: ProfileApiService,
    },
    {
      provide: AvatarConfigServiceToken,
      useValue: {
        ribbonTypes: [],
        crownPath: '',
      }
    },
  ],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error(
        'CoreModule has already been loaded. Import it once, in the AppModule only.'
      );
    }
  }
}
