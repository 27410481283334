import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@material/material.module';
import { ScoreLabelComponent } from '@shared/score-label/score-label.component';

const components = [
  ScoreLabelComponent,
];

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    CommonModule,
    MaterialModule,
  ],
  exports: [
    ...components,
  ]
})
export class ScoreLabelModule {
}
