import { Params } from '@angular/router';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';

import { RouterStateUrl } from './router-state-serializer';
import {UserState, userReducer} from './user/user.reducer';
export interface CoreState {
  router: RouterReducerState<RouterStateUrl>;
  user: UserState;
}

export const coreReducers: ActionReducerMap<CoreState> = {
  router: routerReducer,
  user: userReducer,
};

export const getRouter = createFeatureSelector('router');

export const getRouterState = createSelector(
  getRouter,
  (state: RouterReducerState<RouterStateUrl>) => !!state && state.state
);
export const getRouterUrl = createSelector(
  getRouterState,
  (state: RouterStateUrl) => !!state && state.url
);
export const getRouterData = createSelector(
  getRouterState,
  (state: RouterStateUrl) => !!state && state.data
);
export const getRouterParams = createSelector(
  getRouterState,
  (state: RouterStateUrl) => !!state && state.params
);
export const getRouterQueryParams = createSelector(
  getRouterState,
  (state: RouterStateUrl) => !!state && state.queryParams
);
export const getInnerRouterPath = createSelector(
  getRouterState,
  (state: RouterStateUrl) => !!state && state.innerRouterPath
);
export const getRouterParamsId = createSelector(
  getRouterParams,
  (params: Params) => !!params && params.id
);
export const getRouterQueryParamsModule = createSelector(
  getRouterQueryParams,
  (params: Params) => !!params && params.module
);
export const getRouterQueryParamsType = createSelector(
  getRouterQueryParams,
  (params: Params) => !!params && params.type
);
