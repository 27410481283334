import { Component, Input } from '@angular/core';

import { Job } from '@shared';

@Component({
  selector: 'mojo-shared-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss']
})
export class FormHeaderComponent {
  @Input() header: string;
  @Input() subheader: string;
}
