import { createSelector } from '@ngrx/store';

import { Job } from '@shared/models';

import { CollectionsState, getCollectionsState } from '../collections.reducer';
import { jobsAdapter } from './jobs.reducer';

export const getJobsState = createSelector(
  getCollectionsState,
  (state: CollectionsState) => !!state && state.jobs
);

export const {
  selectIds: getJobsIds,
  selectEntities: getJobsEntities,
  selectAll: getJobsAll,
  selectTotal: getJobsTotal,
} = jobsAdapter.getSelectors<Job>(getJobsState);

export const getJobIsLoaded = createSelector(
  getJobsEntities,
  (entities, { id }) => !!entities && !!id && !!entities[id]
);

export const getJobIsPublished = createSelector(
  getJobsEntities,
  (entities, { id }) => !!entities && !!id ? entities[id]?.published : false
);
