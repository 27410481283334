import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as HttpStatus from 'http-status-codes';
import { Observable, throwError } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';

import { DangerAlert } from '@shared/alert';

import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly auth: AuthService, private readonly store: Store<{}>) { }

  private static shouldAddAuthorization(isAuthenticated: boolean, request: HttpRequest<{}>): boolean {
    return isAuthenticated
      && !request.params.has('bearer')
      && !request.url.includes('blob.core.windows.net');
  }

  intercept(
    request: HttpRequest<{}>,
    next: HttpHandler,
  ): Observable<HttpEvent<{}>> {
    return this.auth.isAuthenticated$.pipe(
      first(),
      switchMap((isAuthenticated) => {

        let headers: HttpHeaders =  new HttpHeaders({
          'X-Mojo-Anonymous-Id': `${this.auth.getIdentity}`,
        });

        const contentType = request.headers.get('Content-Type');

        if (!!contentType) {
          headers = headers.append('Content-Type', contentType);
        }

        if (AuthInterceptor.shouldAddAuthorization(isAuthenticated, request)) {

          headers = headers.append('Authorization', `Bearer ${this.auth.accessToken}`);

          this.auth.renewToken();

        }

        request = request.clone({ headers });

        return next.handle(request)
          .pipe(
            catchError((error: HttpErrorResponse) => {
              if (error.status === HttpStatus.UNAUTHORIZED) {
                this.auth.login();
              }

              if (error.status === HttpStatus.FORBIDDEN) {
                this.store.dispatch(new DangerAlert(`Access denied`));
              }

              return throwError(error);
            }),
          );
      })
    );
  }
}
