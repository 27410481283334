import { OnInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ModalService } from '@shared/modal/moda.service';
import { ModalInfo } from '@shared/models/modal';

@Component({
  selector: 'mojo-form-modal',
  styleUrls: ['./form.modal.component.scss'],
  templateUrl: './form.modal.component.html'
})
export class FormModalComponent implements OnInit {

  constructor(private readonly modalService: ModalService,
              @Inject(MAT_DIALOG_DATA) public data: ModalInfo) {}

  ngOnInit(): void {}

  closeAlert() {
    this.modalService.closeWithAlert();
  }

  close() {
    this.modalService.closeModal();
  }

}
