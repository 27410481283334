import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { getReferral } from '@collections/referrals/referrals.actions';
import { getReferralsState } from '@collections/referrals/referrals.selectors';
import { getSelectedJobBoardId } from '@core/job-board/job-board.selectors';
import { loadReferralFromUrl } from '@core/user/user.actions';
import { isTruthy } from '@shared/utils';

@Injectable({
  providedIn: 'root'
})
export class ReferralGuard implements CanActivate {

  constructor(
    private readonly store: Store,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean> {
    const referralId = +route.queryParams.referral_id;

    return referralId
      ? this.isReferralsStateReady(referralId)
          .pipe(
            map(isTruthy)
          )
      : of(true);
  }

  private isReferralsStateReady(referralId: number) {

    const combined = combineLatest([
      this.store.select(getReferralsState),
      this.store.select(getSelectedJobBoardId),
    ])
    .pipe(
      filter(([referrals, jobBoardId]) => !!referrals && jobBoardId != null)
    );

    return combined
      .pipe(
        tap(() => this.store.dispatch(getReferral({ id: referralId }))),
        map(([v]) => v.entities[referralId]),
        filter((v) => !!v),
        tap((referral) => this.store.dispatch(loadReferralFromUrl({ referral })))
      );
  }
}
