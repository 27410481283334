import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FeatureFlagsGuard } from 'mojo-feature-flags';
import { of } from 'rxjs';
import { catchError, map, switchMap, mergeMap, withLatestFrom, tap } from 'rxjs/operators';

import * as AssociationsActions from '@collections/associations/associations.actions';
import { EeocApiService } from '@core/api/eeoc-api.service';
import { ProfileApiService } from '@core/api/profile-api.service';
import { PublicExperienceApiService } from '@core/api/public-experience-api.service';
import { AuthService } from '@core/auth';
import { getSelectedJobBoardCanonicalName } from '@core/job-board/job-board.selectors';
import { ApplyModuleUtils } from '@shared/models/apply-module-object';

@Injectable()
export class AssociationsEffects {
  createApplyAssociation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssociationsActions.createApplyAssociation),
      withLatestFrom(this.authService.isAuthenticated$),
      switchMap(([{ association, applyModuleObject }, isLoggedIn]) =>
        (isLoggedIn ? this.profileApiService.applyForJobAsAuthorized(association) :
                      this.publicApiService.applyForJob(association))
        .pipe(
          mergeMap((associationCreated) => [
              AssociationsActions.createAssociationSuccess({ association: associationCreated }),
              AssociationsActions.createAssociationEeoc({
                associationId: associationCreated.id,
                eeoc: ApplyModuleUtils.getEeocObject(applyModuleObject, associationCreated.id)
              }),
            ]),
          catchError((error) =>
            of(AssociationsActions.createAssociationFail({ error })))
        )
      )
    ));

  createAssociationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssociationsActions.createAssociationSuccess),
      withLatestFrom(
        this.store.select(getSelectedJobBoardCanonicalName),
        this.authService.isAuthenticated$
      ),
      tap(([{ association }, canonicalName, isLoggedIn]) => {
        if(!isLoggedIn) {
          this.router.navigate(['/' + canonicalName + '/create-password'], { state: { candidateId: association.candidateId } });
        } else {
          this.router.navigate(['/' + canonicalName + '/' + association.reqId + '/apply/thank-you']);
        }
      }),
    ),
    { dispatch: false }
    );

  createAssociationEeoc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssociationsActions.createAssociationEeoc),
      switchMap(({ associationId, eeoc }) => this.eeocApiService.createEeoc(associationId, eeoc)
        .pipe(
          map((eeocCreated) =>
            AssociationsActions.createAssociationEeocSuccess({ eeoc: eeocCreated })),
          catchError((error) =>
            of(AssociationsActions.createAssociationEeocFail({ error })))
        )
      )
    ));

  constructor(
    private readonly actions$: Actions,
    private readonly eeocApiService: EeocApiService,
    private readonly publicApiService: PublicExperienceApiService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly featureFlagsGuard: FeatureFlagsGuard,
    private readonly authService: AuthService,
    private readonly profileApiService: ProfileApiService,
  ) {
  }
}
