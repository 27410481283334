import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';

import { JobBoardApiService } from '@core/api';
import { PublicExperienceApiService } from '@core/api/public-experience-api.service';

import {
  jobBoardsGetDefaultJobBoard,
  jobBoardsGetDefaultJobBoardSuccess,
  jobBoardsGetJobBoards,
  jobBoardsGetJobBoardsSuccess,
} from './job-boards.actions';

@Injectable()
export class JobBoardsEffects {
  getJobBoards$ = createEffect( () => this.actions$.pipe(
    ofType(jobBoardsGetJobBoards),
    switchMap( () => this.jobBoardService.getAllJobBoardsWithoutPagination()
        .pipe(
          map( (jobBoards) => jobBoardsGetJobBoardsSuccess( { jobBoards })),
        ))
  ));

  getDefaultJobBard$ = createEffect(() => this.actions$.pipe(
    ofType(jobBoardsGetDefaultJobBoard),
    switchMap(() => this.publicExperienceApiService.getDefaultJobBoard()
        .pipe(
          map((defaultJobBoard) => jobBoardsGetDefaultJobBoardSuccess({ defaultJobBoard }))
        ))
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly jobBoardService: JobBoardApiService,
    private readonly publicExperienceApiService: PublicExperienceApiService,
  ) { }
}
