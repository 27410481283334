import { createSelector } from '@ngrx/store';

import { jobBoardsAdapter, JobBoardsState } from '@collections/job-boards/job-boards.reducer';
import { JobBoard } from '@shared/models';

import { CollectionsState, getCollectionsState } from '../collections.reducer';

export const getJobBoardsState = createSelector(
  getCollectionsState,
  (state: CollectionsState) => !!state && state.jobBoards
);

export const {
  selectIds: getJobBoardsIds,
  selectEntities: getJobBoardsEntities,
  selectAll: getJobBoardsAll,
  selectTotal: getJobBoardsTotal,
} = jobBoardsAdapter.getSelectors<JobBoard>(getJobBoardsState);

export const getAllActiveNonReferralJobBoards = createSelector(
  getJobBoardsAll,
  (jobBoards) => jobBoards.filter(( jobBoard) =>
    !!jobBoard.status && !(!!jobBoard.standard) && !(!!jobBoard.referralProgram))
);

export const getDefaultJobBoard = createSelector(
  getJobBoardsState,
  (state: JobBoardsState) => !!state && state.defaultJobBoard
);
