import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, map, pluck, mergeMap, filter } from 'rxjs/operators';

import { AttachmentApiService, JobBoardApiService } from '@core/api';
import { JobBoardAttachmentTypeEnum } from '@core/job-board/job-board-attachment-type.enum';
import * as JobsBoardActions from '@core/job-board/job-board.actions';
import { jobsLoadCites, jobsLoadDepartments } from '@modules/jobs/store/jobs-page.actions';
import { isTruthy } from '@shared';
import { DEFAULT_HEADER_BACKGROUND_COLOR, DEFAULT_HEADER_BACKGROUND_OPACITY } from '@shared/models/job-board';

@Injectable()
export class JobBoardEffects {
  getJobBoard$ = createEffect(() => this.actions$.pipe(
    ofType(
      JobsBoardActions.jobBoardGetJobBoard,
      JobsBoardActions.jobBoardSelectBoard,
    ),
    map(({ canonicalName }) => canonicalName),
    filter((text) => isTruthy(text) && text !== 'undefined'),
    distinctUntilChanged(),
    concatMap((canonicalName) =>
      this.jobBoardApiService.getJobBoard(canonicalName)
        .pipe(
          mergeMap((jobBoard) => {
            let overlayBackgroundColor = null;
            let overlayOpacity = 0;
            if (!!jobBoard.headerOverlayStyle) {
              ({ overlayBackgroundColor, overlayOpacity } = JSON.parse(jobBoard.headerOverlayStyle));
            } else if (!jobBoard.headerImageId) {
              overlayBackgroundColor = DEFAULT_HEADER_BACKGROUND_COLOR;
              overlayOpacity = DEFAULT_HEADER_BACKGROUND_OPACITY;
            }

            return [
              JobsBoardActions.jobBoardGetJobBoardSuccess({ jobBoard: { ...jobBoard, overlayBackgroundColor, overlayOpacity } }),
              jobsLoadCites({ canonicalName }),
              jobsLoadDepartments({ canonicalName })];
          }),
          catchError((error) =>
            of(JobsBoardActions.jobBoardGetJobBoardFail({ error }))
          )
        )
    ),
  ));

  requestJobBoardAttachments$ = createEffect(() => this.actions$.pipe(
    ofType(JobsBoardActions.jobBoardGetJobBoardSuccess),
    pluck('jobBoard'),
    concatMap((jobBoard) => [
      jobBoard.headerImageId !== null
        ? JobsBoardActions.jobBoardGetJobBoardAttachment(
          {
            id: jobBoard.id,
            attachmentId: jobBoard.headerImageId,
            actionType: JobBoardAttachmentTypeEnum.HEADER_IMAGE
          })
        : null,
      jobBoard.siteLogoId !== null
        ? JobsBoardActions.jobBoardGetJobBoardAttachment(
          {
            id: jobBoard.id,
            attachmentId: jobBoard.siteLogoId,
            actionType: JobBoardAttachmentTypeEnum.LOGO_IMAGE
          })
        : null,
    ].filter(Boolean))
  ));

  handleAttachmentRequests$ = createEffect(() => this.actions$.pipe(
    ofType(JobsBoardActions.jobBoardGetJobBoardAttachment),
    concatMap(({ id, attachmentId, actionType }) =>
      this.attachmentApiService.getJobBoardAttachment(attachmentId, actionType)
        .pipe(
          map((route) =>
            actionType === JobBoardAttachmentTypeEnum.HEADER_IMAGE
              ? JobsBoardActions.jobBoardGetJobBoardHeaderSuccess({ headerRoute: route })
              : JobsBoardActions.jobBoardGetJobBoardLogoSuccess({ logoRoute: route })
          ),
          catchError((error) =>
            of(JobsBoardActions.jobBoardGetJobBoardFail({ error }))
          )
        )
    )
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly jobBoardApiService: JobBoardApiService,
    private readonly attachmentApiService: AttachmentApiService,
  ) { }
}
