import { HttpClient } from '@angular/common/http';
import { Injectable, Optional, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class LicenseConfigurationService extends ApiService<any> {
  constructor(
    http: HttpClient,
    @Inject(String) @Optional() url: string
  ) {
    super(http, `license/${url || ''}`);
  }

  getLicenseExtension(): Observable<string[]> {
    return this.get({ url: 'extension' });
  }
}
