import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { Job } from '@shared/models';

import * as JobsActions from './jobs.actions';

export type JobsState = EntityState<Job>

export const jobsAdapter: EntityAdapter<Job> = createEntityAdapter<Job>();

export const initialState: JobsState = jobsAdapter.getInitialState();

export const jobsReducer = createReducer(
  initialState,
  on(JobsActions.jobsLoadJobsSuccess, (state, { jobs }) =>
    jobsAdapter.upsertMany(jobs.content, state)
  ),
  on(JobsActions.jobsGetJobDescriptionSuccess, (state, { job }) =>
    jobsAdapter.upsertOne(job, state)
  )
);
