import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserState, userFeatureKey } from './user.reducer';

export const getUserState = createFeatureSelector<UserState>(
  userFeatureKey
);

export const getUserJobApplications = createSelector(
  getUserState,
  (state: UserState) => state.jobApplications
);

export const getUserReferrals = createSelector(
  getUserState,
  (state: UserState) => state.referrals
);

export const getUserReferredJobs = createSelector(
  getUserState,
  (state: UserState) => state.referredJobsIds
);

export const getIsApplied = createSelector(
  getUserJobApplications,
  (jobsApplied: number[], props) => jobsApplied.some((jobId) => jobId === props.jobId)
);

export const getIsReferred = createSelector(
  getUserReferredJobs,
  (referredJobsIds: number[], props) => referredJobsIds.some((jobId) => jobId === props.jobId)
);

export const getLoggedInCeUser = createSelector(
  getUserState,
  (state: UserState) => state.loggedInCeUser
);

export const getLoggedInCandidate = createSelector(
  getUserState,
  (state: UserState) => state.loggedInCandidate
);

export const getCompareApplicationsData = createSelector(
  getUserState,
  (state: UserState) => state.compareApplicationsData
);
