import { createAction, props } from '@ngrx/store';

import { Job } from '@shared/models';
import { Matches } from '@shared/models/matches';
import { Pagination } from '@shared/pagination';

export const profilePageLoadJobsForMatches = createAction(
  '[Profile Page] Load Jobs For Loaded Matches',
  props<{ jobScores: Pagination<Matches> }>()
);

export const profilePageLoadJobsForAssociations = createAction(
  '[Profile Page] Load Jobs For Loaded Associations',
  props<{ jobScores: Pagination<Matches> }>()
);

export const profilePageLoadMatches = createAction(
  '[Profile Page] Load Matches',
  props<{ candidateId: string; offset: number}>()
);

export const profilePageLoadMatchesSuccess = createAction(
  '[Profile Page] Load Matches Success',
  props<{ jobScores: Pagination<Matches> }>()
);

export const profilePageLoadCandidateAssociations = createAction(
  '[Profile Page] Load Candidate Associations',
  props<{ candidateId: string; offset: number }>()
);

export const profilePageLoadCandidateAssociationsSuccess = createAction(
  '[Profile Page] Load Candidate Associations Success',
  props<{ jobScores: Pagination<Matches> }>()
);

export const calculateApplicationsPercentilesDifference = createAction(
  '[Profile Page] Calculate Applications Percentiles Difference',
  props<{ jobScores: Matches[]; applicationsLocalData: string }>()
);

export const calculateApplicationsPercentilesDifferenceSuccess = createAction(
  '[Profile Page] Calculate Applications Percentiles Difference Success',
  props<{ calculatedApplicationsScoresDifferences: Matches[]; jobScores: Matches[] }>()
);

export const loadExperienceBulbDates = createAction(
  '[Profile Page] Load Experience Bulb Dates'
);

export const loadExperienceBulbDatesSuccess = createAction(
  '[Profile Page] Load Experience Bulb Dates Success',
  props<{ lastSeenDate: Date }>()
);

export const updateExperienceBulbLastSeenDate = createAction(
  '[Profile Page] Update Experience Bulb Last Seen Date',
  props<{ date: Date }>()
);

export const clickExperienceBulb = createAction(
  '[Profile Page] Click Experience Bulb'
);

export const loadLastLoginDateFromLocalStorage = createAction(
  '[Profile Page] Load Last Login Date From Local Storage'
);

export const loadLastLoginDateFromLocalStorageSuccess = createAction(
  '[Profile Page] Load Last Login Date From Local Storage Success',
  props<{ lastLoginDate: Date }>(),
);
