import { isDate } from 'date-fns';
import { Observable, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export interface Trackable {
  id: number;
  lastModifiedDate: string;
}

export const trackElement = (index: number, item: Trackable) =>
  `${item.id} ${item.lastModifiedDate}`;

export const persist = (key: string, obj: object) => {
  localStorage.setItem(key, JSON.stringify(obj));
};

export const retrieve = (key: string, initialState: object = {}) => ({
  ...initialState,
  ...(localStorage.hasOwnProperty(key)
    ? JSON.parse(localStorage.getItem(key))
    : {}),
});

export const removeEmpty = (obj = {}) =>
  Object.keys(obj)
    .filter((k) => obj[k] !== null && obj[k] !== undefined)
    .reduce(
      (newObj, k) =>
        typeof obj[k] === 'object' && !isDate(obj[k])
          ? Object.assign(newObj, { [k]: removeEmpty(obj[k]) })
          : Object.assign(newObj, { [k]: obj[k] }),
      {}
    );

export const isTruthy = <T>(input: T): input is T => !!input;

export const listByProperty = (array, property: string): any[] =>
  Array.from(
    new Set(
      array.filter((item) => !!item)
        .map((item) => item[property])
    )
  );

export const isEmpty = (obj: object) =>
  Object.entries(obj).length === 0 && obj.constructor === Object;

export const toInteger = (value: any): number => parseInt(value, 10);

export const isNumber = (value: any): value is number =>
  !isNaN(toInteger(value));

export const removeEmptyFromMap = (obj) =>
  obj
    .keys()
    .filter((k) => obj.get(k) !== null && obj.get(k) !== undefined)
    .reduce(
      (newObj, k) =>
        typeof obj.get(k) === 'object' && !isDate(obj.get(k))
          ? Object.assign(newObj, { [k]: removeEmptyFromMap(obj.get(k)) })
          : Object.assign(newObj, { [k]: obj.get(k) }),
      {}
    );

export const genericRetryStrategy = ({
  maxRetryAttempts = 3,
  scalingDuration = 1000,
  excludedStatusCodes = []
}: {
  maxRetryAttempts?: number;
  scalingDuration?: number;
  excludedStatusCodes?: number[];
} = {}) => (attempts: Observable<any>) => attempts.pipe(
  mergeMap((error, i) => {
    const retryAttempt = i + 1;

    if (
      retryAttempt > maxRetryAttempts ||
      excludedStatusCodes.find((e) => e === error.status)
    ) {
      return throwError(error);
    }

    return timer(retryAttempt * scalingDuration);
  }),
);

export const warningPercentage = (maxLength: number, percentage = 80) => maxLength - (((100 - percentage) / 100) * maxLength);

export const LinkedInRegex = '^(http(s)?:\\/\\/)?([\\w]+\\.)?linkedin\\.com\\/.*$';

export const capitalize = (value: string) =>
  !!value ? value.toLocaleLowerCase()
    .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()) : '';

export function getInitials(name): string {
  return name
    ? !!name &&
    name
      .split(/\s/)
      .map((word: string) => word.charAt(0))
      .slice(0, 2)
      .join('')
      .toUpperCase()
    : '';
}

export const removeNonAsciiChars = (value: string) => {
  if (!value) {
    return '';
  }
  value = decodeUtfBulletsToHTML(value);

  return value.replace(/[^\x00-\x7F]/g, '');
};

export const decodeUtfBulletsToHTML = (value: string) => {
  const valueUsed = value.replace('<0x80>', '&bull;').
    replace('<0xE2>', '&bull;').
    replace('<0xA2>', '&bull;');

  return value;
};
