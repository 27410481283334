import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FeatureFlagsGuard } from 'mojo-feature-flags';
import { of } from 'rxjs';
import { catchError, switchMap, mergeMap, map, withLatestFrom, tap } from 'rxjs/operators';

import * as AssociationsActions from '@collections/associations/associations.actions';
import * as AttachmentsActions from '@collections/attachments/attachments.actions';
import * as CandidatesActions from '@collections/candidates/candidates.actions';
import {
  createCandidatePasswordSuccess,
  loadLastLoginDate,
  loadLastLoginDateSuccess,
} from '@collections/candidates/candidates.actions';
import { ProfileApiService } from '@core/api/profile-api.service';
import { PublicExperienceApiService } from '@core/api/public-experience-api.service';
import { AuthService } from '@core/auth';
import { getSelectedJobBoardCanonicalName } from '@core/job-board/job-board.selectors';
import { UserActions } from '@core/user';
import { loadLoggedInCeUserState } from '@core/user/user.actions';
import { environment } from '@env/environment';
import { DangerAlert } from '@shared/alert';
import { ApplyModuleUtils } from '@shared/models/apply-module-object';

@Injectable()
export class CandidatesEffects {

  private static readonly LAST_LOGIN_DATE = 'lastLoginDate';

  upsertCandidateByApplyModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidatesActions.upsertCandidateByApplyModule),
      withLatestFrom(this.authService.isAuthenticated$),
      switchMap(([{ applyModuleObject }, isLoggedIn]) =>
        (isLoggedIn ? this.profileApiService.updateProfile(ApplyModuleUtils.getCandidateObject(applyModuleObject)) :
          this.applyAsUnauthorizedCandidate(applyModuleObject))
          .pipe(
            map((candidateCreated) => {
              const newApplyModuleObject = { ...applyModuleObject, candidateId: candidateCreated.id };
              if (!!applyModuleObject.resumeFile) {
                return AttachmentsActions.uploadApplyModuleResume({ applyModuleObject: newApplyModuleObject });
              } else {
                return CandidatesActions.upsertCandidateByApplyModuleSuccess({ applyModuleObject: newApplyModuleObject });
              }
            }),
            catchError((error) =>
              of(CandidatesActions.createCandidateFail({ error })))
          )
      )
    ));

  upsertCandidateByApplyModuleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidatesActions.upsertCandidateByApplyModuleSuccess),
      mergeMap(({ applyModuleObject }) => [
        CandidatesActions.saveJobApplicationInfoLocally({ jobId: applyModuleObject.jobId }),
        AssociationsActions.createApplyAssociation({
          applyModuleObject,
          association: ApplyModuleUtils.getAssociationObject(applyModuleObject.candidateId, applyModuleObject.jobId)
        }),
      ]
      ))
  );

  persistJobApplicationInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidatesActions.saveJobApplicationInfoLocally),
      map(({ jobId }) => UserActions.persistApplicationForJob({ jobId }))
    ));

  createCandidateFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidatesActions.createCandidateFail),
      map((error) =>
        this.errorMessage(error)
      )
    ));

  createCandidateFileTooBig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidatesActions.createCandidateFileTooBig),
      map((maxFileSize) =>
        new DangerAlert(
          `File you tried to upload is too big. Max size allowed is ${maxFileSize}MB`
        )),
    ));

  patchCandidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidatesActions.patchCandidate),
      switchMap(({ candidate, resumeFile }) => (candidate.id
        ? this.publicApiService.patchCandidate(candidate)
        : this.publicApiService.createCandidate(candidate))
        .pipe(
          map((patchedCandidate) =>
            CandidatesActions.patchCandidateSuccess({ patchedCandidate })
          ),
          catchError((error) =>
            of(CandidatesActions.patchCandidateFail({ error }))
          ),
        ))
    )
  );

  patchCandidateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidatesActions.patchCandidateSuccess),
      withLatestFrom(this.store.select(getSelectedJobBoardCanonicalName)),
      mergeMap(([{ patchedCandidate }, jobBoardName]) => this.publicApiService.removeGhostCandidate(patchedCandidate.id)
        .pipe(
          tap(() => this.router.navigate([`/${jobBoardName}/create-password`], { state: { candidateId: patchedCandidate.id } })),
        )),
    ),
    {
      dispatch: false
    }
  );

  patchCandidateFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidatesActions.patchCandidateFail),
      map((error) =>
        this.errorMessage(error)
      )
    ));

  createCandidatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidatesActions.createCandidatePassword),
      withLatestFrom(this.store.select(getSelectedJobBoardCanonicalName)),
      switchMap(([{ candidateId, password }, jobBoardName]) => this.publicApiService.createAccount(candidateId, password, jobBoardName)
        .pipe(
          map((tokenDto) => createCandidatePasswordSuccess({ tokenDto })),
          catchError((error) =>
            of(CandidatesActions.createCandidatePasswordFail({ error }))
          ),
        ))
    )
  );

  createCandidatePasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidatesActions.createCandidatePasswordSuccess),
      tap(({ tokenDto }) => {
        const callback = (profile) => {
          this.store.dispatch(loadLoggedInCeUserState());
          this.router.navigate(['/' + profile[environment.USER_METADATA].redirect_url + '/profile/applications']);
        };
        this.authService.setSessionFromAccessToken(tokenDto.accessToken, tokenDto.expiresIn, callback);
      })
    ),
    {
      dispatch: false
    }
  );

  createCandidatePasswordFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CandidatesActions.createCandidatePasswordFail),
      map((error) =>
        new DangerAlert(
          `An error has occurred while creating account. Please try again later.`
        ))
    ));

  loadLastLoginDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLastLoginDate),
      switchMap((_) => this.profileApiService.getLastLoginDate()
        .pipe(
          switchMap((lastLoginDate) => [
            loadLastLoginDateSuccess({ lastLoginDate: new Date(lastLoginDate) }),
          ]),
        )),
    ),
  );

  loadLastLoginDateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLastLoginDateSuccess),
      tap(({ lastLoginDate }) => {
        localStorage.setItem(CandidatesEffects.LAST_LOGIN_DATE, lastLoginDate.toISOString());
      }),
    ), { dispatch: false }
  );

  applyAsUnauthorizedCandidate(applyModuleObject) {
    return applyModuleObject.id ?
      this.publicApiService.patchCandidate(ApplyModuleUtils.getCandidateObject(applyModuleObject)) :
      this.publicApiService.createCandidate(ApplyModuleUtils.getCandidateObject(applyModuleObject));
  }


  constructor(
    private readonly actions$: Actions,
    private readonly publicApiService: PublicExperienceApiService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly featureFlagsGuard: FeatureFlagsGuard,
    private readonly profileApiService: ProfileApiService,
  ) { }

  errorMessage(error) {
    return new DangerAlert(
      `An error has occurred ${error?.error?.message}. Please try again later.`
    );
  }

}
