import { NgModule } from '@angular/core';

import { MaterialModule } from '@app/material/material.module';

import { LoaderComponent } from './loader.component';

@NgModule({
  declarations: [LoaderComponent],
  imports: [MaterialModule],
  exports: [LoaderComponent],
})
export class LoaderModule {}
