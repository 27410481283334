import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

import { Pagination } from './pagination.model';

@Component({
  selector: 'mojo-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input()
  pagination: Pagination;

  @Input()
  disabled = false;

  @Output()
  pageChange: EventEmitter<number> = new EventEmitter();

  change(page: PageEvent) {
    window.scrollTo(0, 0);
    this.pageChange.emit(page.pageIndex);
  }
}
