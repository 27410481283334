import { Tag } from 'mojo-tag';

import { Location } from '@shared/models/location';

import { Trackable } from '../utils';

export interface Job extends Trackable {
  id: number;
  title: string;
  description: string;
  createdDate: string;
  lastModifiedDate: string;
  publishedDate?: Date;
  qualification?: string;
  highLevelRequirements?: string;
  education?: string;
  companyDescription?: string;
  additionalInformation?: string;
  companyName?: string;
  published: boolean;
  locations: Location[];
  remote: boolean;
}

export class JobTagAdapter {
  static toOption(job: Job): Tag {
    return { id: job.id, label: job.title };
  }
}
