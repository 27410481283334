export enum LocationType {
  city = 'CITY',
  county = 'COUNTY',
  area = 'AREA',
  state = 'STATE',
  country = 'COUNTRY'
}

export interface Location {
  city: string;
  countryName: string;
  countryCode?: string;
  stateCode?: string;
  type?: LocationType;
  lon?: number;
  lat?: number;
  name?: string;
  label?: string;
  id?: number;
  state: string;
  location?: string;
}

