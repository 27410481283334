import { HttpClient } from '@angular/common/http';
import { Injectable, Optional, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { ReferralCandidate, ReferredJob } from '@modules/referrals/models';
import { Referral } from '@shared/models/referral';
import { ReferralForm } from '@shared/models/referral-form';
import { Pagination } from '@shared/pagination';

import { ApiParams, ApiService } from './api.service';

@Injectable({providedIn: 'root'})
export class ReferralApiService extends ApiService<Referral> {
  constructor(
    http: HttpClient,
    @Inject(String) @Optional() url: string
  ) {
    super(http, `${url || ''}`);
  }

  getReferral(id: number): Observable<Referral> {
    return this.get<Referral>({url: `candidates/public/experience/referrals/${id}` });
  }

  load(ids: number[]) {
    return this.post<Referral[]>({url: 'candidates/public/experience/referrals/list/', body: ids});
  }

  createReferral(body: ReferralForm): Observable<Referral> {
    return this.post({ url: 'candidates/public/experience/referrals/', body });
  }

  getHistory(jobBoardId, params: ApiParams): Observable<Pagination<ReferralCandidate>> {
    return this.get({ url: `candidates/experience/job-board/${jobBoardId}/referral-history`, params });
  }

  getCandidateHistory(jobBoardId: number, candidateId: number, candidateJobIndexOffset: number): Observable<ReferredJob[]> {
    return this.get(
      { url: `candidates/experience/job-board/${jobBoardId}/candidate/${candidateId}/referral-history`,
      params: { candidateJobIndexOffset }}
    );
  }

  downLoadResume(jobBoardId: number, candidateId: number) {
    return this.get<Blob>({
      url: `candidates/experience/job-board/${jobBoardId}/candidate/${candidateId}/resume`,
      params: { download: ''},
      responseType: 'blob'
    });
  }

  isCandidateReferredToJobByUser(jobBoardId: number, candidateEmail: string, jobId: number) {
    return this.get<boolean>({
      url: `candidates/experience/job-board/${jobBoardId}/job/${jobId}/candidate?isReferredByUser`,
      params: {
        email: candidateEmail
      }
    });
  }
}
