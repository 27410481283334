import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  PaginationActionsUnion,
  PaginationActionTypes,
} from './pagination.actions';
import { Pagination } from './pagination.model';

export const initialState: Pagination = {};

export interface PaginationCustomActionOptions {
  load?: string;
  success?: string;
  fail?: string;
  clear?: string;
}

export const paginationReducer = (
  customActions: PaginationCustomActionOptions = {},
  customInitialState: Pagination = initialState
) => (
  state = customInitialState,
  action: PaginationActionsUnion | any
): Pagination => {
  switch (action.type) {
    case customActions.load:
    case PaginationActionTypes.Load:
      return {
        ...state,
        loading: true,
      };

    case customActions.success:
    case PaginationActionTypes.Success:
      return {
        ...state,
        ...action.page,
        content:
          !!action.page.content && action.page.content.map(({ id }) => ({ id })),
        loading: false
      };
    case customActions.fail:
    case PaginationActionTypes.Fail:
      return {
        ...state,
        loading: false,
      };

    case PaginationActionTypes.ChangePage:
      return { ...state, number: action.pageNumber, loading: true };

    case customActions.clear:
    case PaginationActionTypes.Clear:
      return {
        ...customInitialState,
      };

    default:
      return state;
  }
};

export const getPagination = createFeatureSelector<Pagination>('pagination');

export const getPaginationNumber = createSelector(
  getPagination,
  (state: Pagination) => !!state && state.number
);
export const getPaginationSize = createSelector(
  getPagination,
  (state: Pagination) => !!state && state.size
);
export const getPaginationTotalElements = createSelector(
  getPagination,
  (state: Pagination) => !!state && state.totalElements
);
