import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { PageLayoutComponent } from './page-layout.component';

@NgModule({
  declarations: [PageLayoutComponent],
  imports: [FormsModule, CommonModule],
  exports: [PageLayoutComponent],
})
export class PageLayoutModule { }
