import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';

import { showModalAlert, confirmModal, cancelModal, resetModal } from '@shared/modal/modal.actions';
import { ModalAlert, Modal} from '@shared/models/modal';

export const initialStateAlert: ModalAlert = {
  title: '',
  message: '',
  confirmText: 'Yes',
  cancelText: 'No',
  isConfirmed: false,
  canDecline: true,
  content: '',
  actions: [],
};

export const initialState: Modal = {
  alert: initialStateAlert,
};

const reducer = createReducer(
  initialState,
  on(showModalAlert, (state, {currentState}) => ({ alert: { ...state.alert, ...currentState} })),
  on(confirmModal, (state, currentState) => ({ alert: { ...state.alert, ...currentState, isConfirmed: true } })),
  on(cancelModal, (state) => ({ alert: { ...state.alert, isCancelled: true } })),
  on(resetModal, (_) => ({ ...initialState }))
);

export function modalReducer(state: Modal | undefined, action: Action) {
  return reducer(state, action);
}

export const modalState = createFeatureSelector<Modal>('modal');
