import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { TermsAndConditionsModalComponent } from '@shared/terms-and-conditions-modal/terms-and-conditions-modal.component';


@NgModule({
  declarations: [
    TermsAndConditionsModalComponent,
  ],
  exports: [
    TermsAndConditionsModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
  ]
})
export class TermsAndConditionsModalModule {}
