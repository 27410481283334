import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { getDefaultJobBoard } from '@collections/job-boards/job-boards.selectors';


@Injectable({ providedIn: 'root' })
export class DefaultBoardGuard implements CanActivate, OnDestroy {

  private readonly sub: Subscription = new Subscription();

  constructor(
    private readonly store: Store<{}>,
    private readonly router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.sub.add(this.store.select(getDefaultJobBoard)
      .subscribe((defaultJobBoard) => {
        if (defaultJobBoard != null) {
          this.router.navigateByUrl(`/${defaultJobBoard}`);
        }
      }));

    return true;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
