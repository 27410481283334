import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { Job } from '@shared/models';
import { Pagination } from '@shared/pagination';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class JobDescriptionApiService extends ApiService<Pagination<Job>> {

  constructor(
    http: HttpClient,
    @Inject(String) @Optional() url: string
  ) {
    super(http, `job-description/${url || ''}`);
  }

  departments(board, allJobs: boolean) {
    return this.get<string[]>({ url: `board/${board}/departments`, params: {allJobs}  });
  }

  cities(board, allJobs: boolean) {
    return this.get<string[]>({ url: `board/${board}/cities`, params: {allJobs} });
  }

  load(ids: number[]) {
    const queryList = ids.join(',');

    return this.get<Job[]>({ url: `/list?${queryList}` });
  }
}
