import { SocialInfoType } from '@shared/models/social-info';

import { Association } from './association';
import { Attachment } from './attachment';
import { Candidate } from './candidate';
import { Eeoc } from './eeoc';

export interface ApplyModuleObject {
  id?: number;
  resumeFile: File;
  firstName: string;
  lastName: string;
  emailId: string;
  mobile: string;
  linkedInId?: string;
  gender: string;
  race: string;
  veteranStatus: string;
  referralId: number;
  referredBy: string;
  candidateId: number;
  jobId: number;
  jobBoardName?: string;
}

export class ApplyModuleUtils {
  static getCandidateObject(applyModule: ApplyModuleObject): Candidate {
    return {
      id: applyModule.id,
      firstName: applyModule.firstName,
      lastName: applyModule.lastName,
      emailId: applyModule.emailId,
      mobile: applyModule.mobile,
      socialInfo: [{
        url: applyModule.linkedInId,
        type: SocialInfoType.LINKEDIN
      }],
      gender: applyModule.gender,
      referralId: applyModule.referralId,
      source: !!applyModule.referralId ? 'Referral' : 'Other',
      sourceNote: !!applyModule.jobBoardName ?
        !!applyModule.referralId ? `Referral Job Board: ${applyModule.jobBoardName}` : `Job Board: ${applyModule.jobBoardName}`
        : undefined
    };
  }

  static getAssociationObject(candidateId: number, jobId: number): Association {
    return {
      candidateId,
      reqId: jobId,
      status: 'Associated'
    };
  }

  static getAttachmentObject(candidateId: number): Attachment {
    return {
      associateModuleId: candidateId,
      associationModule: 'Candidate',
      associationType: 'resume',
      name: '',
      path: ''
    };
  }

  static getEeocObject(applyModule: ApplyModuleObject, associationId: number): Eeoc {
    return {
      associationId,
      gender: applyModule.gender,
      race: applyModule.race,
      veteranStatus: applyModule.veteranStatus,
    };
  }
}
