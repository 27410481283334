import { createSelector, createFeatureSelector } from '@ngrx/store';

import { jobBoardFeatureKey } from '@core/job-board/job-board.reducer';
import { JobBoard } from '@shared/models/job-board';


export const getJobBoardState = createFeatureSelector<JobBoard>(
  jobBoardFeatureKey
);

export const getJobBoard = createSelector(
  getJobBoardState,
  (state: JobBoard) => state
);

const selectJobBoardByCanonicalName = (jobBoard: JobBoard, jobBoardCanonicalName: string) =>
  jobBoard?.canonicalName === jobBoardCanonicalName ?  jobBoard : null;

export const getJobBoardByCanonicalName = createSelector(
  getJobBoard,
  selectJobBoardByCanonicalName,
);

export const getSelectedJobBoardCanonicalName = createSelector(
  getJobBoardState,
  (state) => state?.canonicalName,
);


export const getSelectedJobBoardId = createSelector(
  getJobBoard,
  (board) => board?.id
);

export const getJobBoardLogoRoute = createSelector(
  getJobBoard,
  (board) => board?.logoRoute,
);

export const getJobBoardHeaderRoute = createSelector(
  getJobBoard,
  (board) => board?.headerRoute,
);

export const getReferralProgram = createSelector(
  getJobBoard,
  (board: JobBoard) => board.referralProgram
);
