import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@core/api/api.service';
import { JobBoard, JobBoardRaw } from '@shared/models/job-board';
import { Matches } from '@shared/models/matches';
import { Pagination } from '@shared/pagination';

@Injectable({
  providedIn: 'root'
})
export class JobBoardApiService extends ApiService<JobBoardRaw> {

  constructor(
    http: HttpClient,
    @Inject(String) @Optional() url: string
  ) {
    super(http, `job-board/${url || ''}`);
  }

  getJobBoard(canonicalName: string): Observable<JobBoardRaw> {
    return this.get({ url: `canonical/${canonicalName}?status=true` });
  }

  getJobBoardJobsScoreForCandidate(jobBoardId: number,
                                   candidateId: number,
                                   offset: number,
                                   withoutAssociationFlag?: boolean,
                                   isFeatureExtensionOn?: boolean ): Observable<Pagination<Matches>> {
    const params = isFeatureExtensionOn ? { offset, allJobs: true } : { offset };
    const url = !!withoutAssociationFlag ?
      `${jobBoardId}/candidate/${candidateId}/percentiles?notAssociated` : `${jobBoardId}/candidate/${candidateId}/percentiles`;

    return this.get({ url, params });
  }

  getJobBoardJobsScoreForCandidateAssociations(candidateId: number, offset: number): Observable<Pagination<Matches>> {
    return this.get({ url: `candidate/${candidateId}/percentiles?associations`, params: { offset }});
  }

  getAllJobBoardsWithoutPagination(): Observable<JobBoard[]> {
    return this.get({ url: 'list' });
  }
}
