import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { GeolocationService } from '@core/services/geolocation.service';

import { LocationInputComponent } from './location-input.component';
const containers = [LocationInputComponent];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatIconModule,
  ],
  exports: [...containers],
  declarations: [...containers],
  providers: [GeolocationService]
})
export class LocationInputModule {}
