import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { JobBoard } from '@shared/models';

import { jobBoardsGetDefaultJobBoardSuccess, jobBoardsGetJobBoardsSuccess } from './job-boards.actions';

export interface JobBoardsState extends EntityState<JobBoard> {
  defaultJobBoard: string;
}

export const jobBoardsAdapter: EntityAdapter<JobBoard> = createEntityAdapter<JobBoard>();

export const initialState: JobBoardsState = jobBoardsAdapter.getInitialState({
  defaultJobBoard: null
});

export const jobBoardsReducer = createReducer(
  initialState,
  on(jobBoardsGetJobBoardsSuccess, (state, { jobBoards }) =>
    jobBoardsAdapter.upsertMany(jobBoards, state)
  ),
  on(jobBoardsGetDefaultJobBoardSuccess, (state, { defaultJobBoard }) =>
    ({...state, defaultJobBoard })
  ),
);
