export interface IconConfig {
  iconsBasePath: string;
  images: string[];
  icons: string[];
  logos: string[];
  social: string[];
}

export const ICON_CONFIG: IconConfig = {
  iconsBasePath: 'assets/icons/',
  images: [
    'homepage-empty-search',
  ],
  icons: ['fire_red'],
  logos: ['italent'],
  social: [
    'crunchbase',
    'facebook',
    'github',
    'instagram',
    'linkedin',
    'pinterest',
    'twitter',
    'youtube',
    'crying',
  ],
};
