import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, pluck, switchMap, withLatestFrom } from 'rxjs/operators';

import { AppFeatureExtensions } from '@app/app-feature-extensions.config';
import { JobDescriptionApiService } from '@core/api';
import { getSelectedJobBoardCanonicalName } from '@core/job-board/job-board.selectors';
import { checkIfLicenseContainsFeatureExtension } from '@core/license-configuration/store/license-configuration.selectors';
import { removeNonAsciiChars } from '@shared';
import { Job } from '@shared/models';

import * as JobsActions from './jobs.actions';

@Injectable()
export class JobsEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.jobsGetJobDescription),
      switchMap(({ id }) =>
        this.jobDescriptionApiService.get<Job>({ url: id.toString() })
          .pipe(
            map((job) => ({
              ...job,
              description: removeNonAsciiChars(job.description),
            })),
            map((job) => JobsActions.jobsGetJobDescriptionSuccess({ job })),
            catchError((error) => of(JobsActions.jobsGetJobDescriptionFail({ error })))
          )
      )
    )
  );

  loadPublishedJobs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobsActions.jobsLoadJobs),
      pluck('payload'),
      withLatestFrom(
        this.store.select(getSelectedJobBoardCanonicalName),
        this.store.select(checkIfLicenseContainsFeatureExtension, { featureExtension: AppFeatureExtensions.REFERRAL_EXTENDED }),
        ),
      filter(([_, jobBoardCanonicalName]) => !!jobBoardCanonicalName),
      mergeMap(([{ params = {}, body = {} }, jobBoardCanonicalName, isFeatureExtensionOn]) => {
        if (isFeatureExtensionOn) {
          params = body.ids.length === 0 ? { ...params, allJobs: true } : { ...params, useJobIdsFromFilter: true };
        }

        return this.jobDescriptionApiService.post({
          url: 'board/' + jobBoardCanonicalName,
          params,
          body,
        })
          .pipe(
            map((jobs) => {
              jobs.content.forEach((job) => job.description = removeNonAsciiChars(job.description));

              return jobs;
            }),
            map((jobs) => JobsActions.jobsLoadJobsSuccess({ jobs })),
            catchError((error) => of(JobsActions.jobsLoadJobsFail({ error })))
          );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly jobDescriptionApiService: JobDescriptionApiService,
    private readonly store: Store,
  ) { }
}
