import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { NavMenuRoute, NavMenuGroups, MenuHeaderData } from 'mojo-nav-menu';
import { Observable, Subscription, of, combineLatest } from 'rxjs';
import { tap, switchMap, map, filter, shareReplay } from 'rxjs/operators';

import { AppFeatureExtensions } from '@app/app-feature-extensions.config';
import { AuthService } from '@core/auth';
import { getJobBoardLogoRoute, getJobBoard, getReferralProgram } from '@core/job-board/job-board.selectors';
import { checkIfLicenseContainsFeatureExtension } from '@core/license-configuration/store/license-configuration.selectors';
import { getLoggedInCandidate, getLoggedInCeUser } from '@core/user/user.selectors';
import { JobBoard, isTruthy } from '@shared';
import { IsMobileViewService } from '@shared/is-mobile-view.service';

const LOG_OUT = 'Log out';
@Component({
  selector: 'mojo-layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {

  constructor(
    private readonly store: Store<{}>,
    private readonly titleService: Title,
    private readonly authService: AuthService,
    private readonly isMobileViewService: IsMobileViewService,
  ) {}

  routes: NavMenuRoute[] = [];

  headerData: MenuHeaderData;

  isAuthenticated$ = this.authService.isAuthenticated$.pipe(
    switchMap((isAuth) => {
      if (isAuth) {
        return this.store.select(getLoggedInCeUser)
          .pipe(
            filter(isTruthy),
            tap(({firstName, lastName}) => {
              let photoId;
              this.subs.push(this.store.select(getLoggedInCandidate)
                .pipe(
                  filter(isTruthy)
                )
                .subscribe((candidate) => {
                  photoId = candidate.candidateImageId;

                  this.headerData = {...this.headerData, photoId: candidate.candidateImageId };
                }));

              this.headerData = {
                firstName,
                lastName,
                photoId,
              };
            }),
          map(() => of(true))
        );
      }

      return of(false);
    }),
    shareReplay(1)
  );

  referralProgram$ = this.store.select(getReferralProgram);

  logoRoute$: Observable<string> = this.store.select(getJobBoardLogoRoute);

  jobBoard$: Observable<JobBoard> = this.store.select(getJobBoard);


  hasExtendedReferralExtension$: Observable<boolean> =
    this.store.select(checkIfLicenseContainsFeatureExtension, { featureExtension: AppFeatureExtensions.REFERRAL_EXTENDED });


  referralHistoryUrl: string;

  subs: Array<Subscription> = [];

  ngOnInit(): void {
    this.subs.push(combineLatest([this.jobBoard$, this.hasExtendedReferralExtension$, this.isMobileViewService.isMobileView$()])
    .subscribe(
      ([jobBoard, hasExtendedReferral, isMobile]) => {
        if (hasExtendedReferral) {
          isMobile ? this.buildMobileReferralRoutes(jobBoard?.canonicalName) : this.buildOnlyLogoutRoutes();
          this.referralHistoryUrl = `${jobBoard?.canonicalName}/referral-history`;
        } else {
          this.buildRoutes(jobBoard.canonicalName);
        }
        this.titleService.setTitle(jobBoard?.jobBoardName);
    }));
  }

  buildRoutes(jobBoardCanonicalName: string) {
    this.routes =  [
      {
        label: 'Matches',
        url: `${jobBoardCanonicalName}/profile/matches`,
      },
      {
        label: 'Applications',
        url: `${jobBoardCanonicalName}/profile/applications`,
      },
      {
        label: 'Professional profile',
        url: `${jobBoardCanonicalName}/profile/professional-profile`,
      },
      {
        label: 'Settings',
        url: `${jobBoardCanonicalName}/settings`,
        group: NavMenuGroups.AUTH,
      },
      {
        label: LOG_OUT,
        url: null,
        group: NavMenuGroups.AUTH
      },
    ];
  }

  buildOnlyLogoutRoutes() {
    this.routes =  [
      {
        label: LOG_OUT,
        url: null,
        group: NavMenuGroups.AUTH
      },
    ];
  }

  buildMobileReferralRoutes(jobBoardCanonicalName: string) {
    this.routes =  [
      {
        label: 'Make referral',
        url: jobBoardCanonicalName,
      },
      {
        label: 'Referral history',
        url: this.referralHistoryUrl,
      },
      {
        label: LOG_OUT,
        url: null,
        group: NavMenuGroups.AUTH
      },
    ];
  }

  navigate(route: NavMenuRoute) {
    if (route.label === LOG_OUT) {
      this.authService.logout();
    }
  }

  loginUser() {
    this.authService.login();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
