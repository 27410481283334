import { Trackable } from '../utils';

export interface JobBoardRaw extends Trackable {
  createdById: string;
  createdByName: string;
  createdDate: string;
  headerImageId: number;
  headerText: string;
  jobBoardName: string;
  lastModifiedById: string;
  lastModifiedByName: string;
  referralProgram: boolean;
  siteLogoId: number;
  status: boolean;
  subheaderText: string;
  jobPosts?: number;
  isPublished?: boolean;
  jobBoardPostId?: number;
  canonicalName?: string;
  headerOverlayStyle: string;
  overlayBackgroundColor?: string;
  overlayOpacity?: number;
  standard?: boolean;
}

export interface JobBoard extends JobBoardRaw {
  headerRoute?: string;
  logoRoute?: string;
}

export const DEFAULT_HEADER_BACKGROUND_COLOR = '#cacaca';
export const DEFAULT_HEADER_BACKGROUND_OPACITY = 100;
