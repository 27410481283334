import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Modal, ModalAlert } from '@shared/models/modal';


export const getModal = createFeatureSelector<Modal>('modal');

export const getAlertState = createSelector(
  getModal,
  (state: Modal) => !!state && state.alert,
);

export const isModalConfirmed = createSelector(
  getAlertState,
  (state: ModalAlert) => !!state && state.isConfirmed,
);

