import { createAction, props } from '@ngrx/store';

import { Referral } from '@shared/models/referral';
import { ReferralForm } from '@shared/models/referral-form';

export const getReferral = createAction(
  '[Referrals] Get Referral',
  props<{ id: number }>()
);

export const getReferralSuccess = createAction(
  '[Referrals] Get Referral Success',
  props<{ referral: Referral }>()
);

export const getReferralFail = createAction(
  '[Referrals] Get Referrals Fail',
  props<{ error: any }>()
);

export const createReferral = createAction(
  '[Referrals] Create Referral',
  props<{ referralForm: ReferralForm }>()
);

export const createReferralSuccess = createAction(
  '[Referrals] Create Referrals Success',
  props<{ referral: Referral }>()
);

export const createReferralFail = createAction(
  '[Referrals] Create Referrals Fail',
  props<{ error: any }>()
);

export const loadReferralSuccess = createAction(
  '[Referrals] Load Referrals Success',
  props<{ referrals: Referral[] }>()
);

export const selectReferral = createAction(
  '[Referrals] Select Referral',
  props<{ referralId: number }>()
);
