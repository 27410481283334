import { createReducer, on } from '@ngrx/store';

import { JobBoard } from '@shared/models/job-board';

import * as JobBoardActions from './job-board.actions';


export const initialState: Partial<JobBoard> = {
  canonicalName: null,
};

export const jobBoardFeatureKey = 'jobBoard';

export const jobBoardReducer = createReducer(
  initialState,
  on(JobBoardActions.jobBoardSelectBoard, (state, { canonicalName }) => ({
    ...state,
    canonicalName,
  })),

  on(JobBoardActions.jobBoardGetJobBoardSuccess, (state, { jobBoard }) => ({
    ...state,
    ...jobBoard
  })),
  on(JobBoardActions.jobBoardGetJobBoardSuccess, (state, { jobBoard }) => ({
    ...state,
    ...jobBoard
  })),
  on(JobBoardActions.jobBoardGetJobBoardHeaderSuccess, (state, {  headerRoute }) => ({
    ...state,
    headerRoute
  })),

  on(JobBoardActions.jobBoardGetJobBoardLogoSuccess, (state, {  logoRoute }) => ({
    ...state,
    logoRoute
  })),
);
