import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';

import { GeolocationService } from '@core/services/geolocation.service';
import { Location } from '@shared/models';
import { isTruthy } from '@shared/utils';

@Component({
  selector: 'mojo-location-input',
  templateUrl: './location-input.component.html',
  styleUrls: ['./location-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationInputComponent implements OnInit {
  @Input()
  label: string;

  @Input()
  form: FormGroup;

  @Input()
  controlName: string;

  @Input()
  required = true;

  locations$: Observable<Location[]>;

  constructor(private readonly geolocationService: GeolocationService) {}

  ngOnInit(): void {
    this.locations$ = this.form.get(this.controlName).valueChanges
      .pipe(
        filter((name) => typeof name === 'string'),
        map((name: string) => name.split(',')[0]),
        debounceTime(250),
        switchMap((name: string) => this.geolocationService.autocomplete(name))
    );
  }

  locationName(location: Partial<Location>) {
    return !!location
      ? [
        location.name,
        ...(location.countryCode === 'US' || !!location.state ? [location.stateCode] : []),
        location.countryName,
      ]
        .filter(isTruthy)
        .join(', ')
      : '';
  }
}
