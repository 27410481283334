import { Injectable } from '@angular/core';
import { PRIMARY_OUTLET, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  Auth0DecodedHash,
  Auth0UserProfile,
  AuthorizeOptions,
  WebAuth,
} from 'auth0-js';
import { MojoMonitoringService } from 'mojo-monitoring';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, first, map, shareReplay } from 'rxjs/operators';
import * as uuid from 'uuid';

import { AppFeatureExtensions } from '@app/app-feature-extensions.config';
import { loadLastLoginDate } from '@collections/candidates/candidates.actions';
import { jobBoardsGetDefaultJobBoard } from '@collections/job-boards/job-boards.actions';
import { AUTH0_TOKEN_EXPIRATION_PERIOD_MS } from '@core/auth/auth.const';
import { tenantNameFromPath, toTenantUrl } from '@core/auth/path.utils';
import { licenseConfigurationGetExtensions } from '@core/license-configuration/store/license-configuration.actions';
import { checkIfLicenseContainsFeatureExtension } from '@core/license-configuration/store/license-configuration.selectors';
import { compareApplicationsDataUpdate } from '@core/user/user.actions';
import { environment } from '@env/environment';
import { SuccessAlert } from '@shared/alert';

export interface Auth0State {
  targetUrl: string;
  nonceRandomValue: number;
  href: string;
}

@Injectable()
export class AuthService {
  // when handling authentication redirect we should stop angular navigation
  // handled via AuthGuard
  preventNavigation = false;

  private isRenewing = false;

  get accessToken(): string {
    return localStorage.getItem('access_token');
  }

  private readonly auth0: WebAuth = new WebAuth({
    clientID: environment.AUTH0_CLIENT_ID,
    domain: environment.AUTH0_DOMAIN,
    responseType: 'token id_token',
    audience: environment.AUTH0_AUDIENCE,
    redirectUri: toTenantUrl(environment.AUTH0_REDIRECT_UI),
    scope: 'openid email profile',
  });

  private readonly initialized$ = new ReplaySubject(1);

  isAuthenticated$ = this.initialized$.pipe(
    map(() => Date.now() < Number(localStorage.getItem('expires_at'))),
    shareReplay(1),
  );

  constructor(
    private readonly router: Router,
    private readonly store: Store<{}>,
    private readonly monitoringService: MojoMonitoringService,
  ) { }

  setSessionFromAccessToken(accessToken: string, expiresIn: number, callback) {
    this.getUser({ accessToken, idToken: accessToken, expiresIn }, callback);
  }

  handleAuthentication() {
    this.store.dispatch(licenseConfigurationGetExtensions());
    this.store.dispatch(jobBoardsGetDefaultJobBoard());
    const session = this.getSession();
    if (session?.expiresIn > 0 || !!session?.accessToken) {
      // recover exisitng session
      return this.renewToken()
        .then(() => {
          this.initialized$.next();
          this.handleAuthorizationActionsDuringLogin();

          return true;
        });
    } else {
      // handle new session
      // collect token from url
      return new Promise<boolean>((resolve, reject) => this.auth0.parseHash((error, result: Auth0DecodedHash) => {

        this.executeCode(result);

        resolve(!!result);
      }));
    }
  }
  executeCode(result: Auth0DecodedHash) {
    if (!!result) {
      this.handleAuthorizationActionsDuringLogin();
      const callback = (profile) => profile ?
        this.handleLoginRedirectionBasedOnSSO(profile) :
        this.router.navigate(['/']);
      this.getUser(result, callback);
      this.store.dispatch(loadLastLoginDate());
    } else {
      (tenantNameFromPath() === 'fmi' || tenantNameFromPath() === 'proddemo') ? this.login() : this.initialized$.next();
    }

  }

  login() {
    this.preventNavigation = true;

    return this.authorizationParams()
      .subscribe((params) => this.auth0.authorize(params));
  }

  logout(authorizeAfterLogout: boolean = false) {
    this.preventNavigation = true;
    localStorage.removeItem('ce_user_id');
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_email');
    localStorage.removeItem('candidate_id');
    localStorage.removeItem('authorizeAfterLogout');
    localStorage.setItem('redirectAfterLogout', this.getRedirect());
    localStorage.setItem('authorizeAfterLogout', `${authorizeAfterLogout}`);
    localStorage.removeItem('redirect');

    return this.auth0.logout({
      returnTo: `${toTenantUrl(environment.AUTH0_LOGOUT_URL)}`,
    });
  }

  private getRedirect() {
    const path = this.router.parseUrl(this.router.url)?.root?.children[PRIMARY_OUTLET]?.segments[0]?.path;
    return !!path? path : localStorage.getItem('redirect');
  }

  renewToken() {
    const expiresAt = Number(localStorage.getItem('expires_at'));

    if (expiresAt < Date.now()) {
      this.logout();
    } else if (this.isReadyForRenew(expiresAt) && !this.isRenewing) {
      this.isRenewing = true;

      return new Promise<void>((resolve) =>
        this.authorizationParams()
          .subscribe((params) => {
            this.auth0.checkSession(params, (err, result) => {
              this.isRenewing = false;
              if (err) {
                this.logout();
              } else {
                this.setSession(result);
              }
              resolve();
            });
          })
      );
    }

    return Promise.resolve();
  }

  private isReadyForRenew(expiresAt: number) {
    const timeToTokenDeactivationFromNowMs = expiresAt - Date.now();
    const allowedTimeWithoutRenewalMs = 30 * 60 * 1000;

    return timeToTokenDeactivationFromNowMs < AUTH0_TOKEN_EXPIRATION_PERIOD_MS - allowedTimeWithoutRenewalMs;
  }

  get getIdentity() {
    const anonymousKey = 'anonymous_id';
    let userId = localStorage.getItem(anonymousKey);
    if (!userId) {
      userId = uuid.v4();
      localStorage.setItem(anonymousKey, userId);
    }

    return userId;

  }

  private getUser(authResult: Auth0DecodedHash, callback) {
    return this.auth0.client.userInfo(
      authResult.accessToken,
      (err, profile: Auth0UserProfile) => {
        if (profile) {
          this.monitoringService.setUser({
            userName: profile.name,
            userEmail: profile.email,
          });
          localStorage.setItem('user_name', profile.name);
          localStorage.setItem('user_email', profile.email);
          localStorage.setItem('redirect', profile[environment.USER_METADATA].redirect_url);
          localStorage.setItem('candidate_id', profile[environment.USER_METADATA].id);
          localStorage.setItem('ce_user_id', profile[environment.USER_METADATA].ce_user_id);
          this.store.dispatch(
            new SuccessAlert(
              `Welcome to the MojoRank platform ${profile.nickname}`,
            ),
          );
        }
        localStorage.setItem('tenant', tenantNameFromPath());

        this.setSession(authResult);

        callback(profile);

      },
    );
  }

  private authorizationParams(): Observable<AuthorizeOptions> {
    return this.store.select(
      checkIfLicenseContainsFeatureExtension, { featureExtension: AppFeatureExtensions.CE_AUTH0_CONNECTION_EXTENSION }
    )
      .pipe(
        /** TODO fix these hard coded tenants */
        filter((ceConnectionExtension) =>
          (tenantNameFromPath() === 'fmi' || tenantNameFromPath() === 'proddemo') || ceConnectionExtension !== undefined
        ),
        map((includeCeExtension) => includeCeExtension ? `${tenantNameFromPath()}-ce` : tenantNameFromPath()),
        map((connection) => ({ connection })),
        first(),
      );
  }

  private setSession(authResult: Auth0DecodedHash) {
    const expiresAt = String(authResult.expiresIn * 1000 + Date.now());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    this.initialized$.next();
  }

  private getSession(): Auth0DecodedHash {
    return {
      accessToken: localStorage.getItem('access_token'),
      idToken: localStorage.getItem('id_token'),
      expiresIn: (+localStorage.getItem('expires_at') - Date.now()) / 1000,
    };
  }

  private handleAuthorizationActionsDuringLogin() {
    localStorage.setItem('applicationsScores', localStorage.getItem('applicationsScoresTemp'));
    this.store.dispatch(compareApplicationsDataUpdate({ value: true }));
  }

  private handleLoginRedirectionBasedOnSSO(profile) {
    profile[environment.USER_METADATA].is_sso ? this.router.navigate(['/' + profile[environment.USER_METADATA].redirect_url]) :
      this.router.navigate(['/' + profile[environment.USER_METADATA].redirect_url + '/profile']);
  }
}
