import { Directive, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[mojoFormConnect]'
})
export class FormConnectDirective {
  @Input('mojoFormConnect')
  set data(val: any) {
    if (!!val) {
      this.formGroupDirective.form.patchValue(val);
      this.formGroupDirective.form.markAsPristine();
    }
  }

  constructor(
    private readonly formGroupDirective: FormGroupDirective,
  ) { }
}
