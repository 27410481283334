import { createAction, props } from '@ngrx/store';

import { ApiParams } from '@core/api';
import { JobPageFilters } from '@modules/jobs/store/jobs-page.reducer';
import { Job } from '@shared/models';
import { Pagination } from '@shared/pagination';

export const jobsGetJobDescription = createAction(
  '[Collections Jobs] Get Job Description',
  props<{ id: number }>()
);

export const jobsGetJobDescriptionSuccess = createAction(
  '[Collections Jobs] Get Job Description Success',
  props<{ job: Job }>()
);

export const jobsGetJobDescriptionFail = createAction(
  '[Collections Jobs] Get Job Description Fail',
  props<{ error: any }>()
);

export const jobsLoadJobs = createAction(
  '[Collections Jobs] Load Jobs',
  props<{ payload?: { params?: ApiParams; body?: JobPageFilters } }>()
);

export const jobsLoadJobsSuccess = createAction(
  '[Collections Jobs] Load Jobs Success',
  props<{ jobs: Pagination<Job> }>()
);

export const jobsLoadJobsFail = createAction(
  '[Collections Jobs] Load Jobs Fail',
  props<{ error?: any }>()
);
