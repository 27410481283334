import { listByProperty } from '@shared/utils';

export interface Pagination<T = {}> {
  last?: boolean;
  totalElements?: number;
  totalPages?: number;
  size?: number;
  number?: number;
  first?: boolean;
  numberOfElements?: number;
  content?: T[];

  contentIds?: number[];

  loading?: boolean;
}

export class PaginationAdapter<T = {}> implements Pagination<T> {
  content?: T[];

  get contentIds(): number[] {
    return !!this.content && listByProperty(this.content, 'id');
  }

  constructor(
    payload?: Partial<Pagination<T>>
  ) {
    Object.assign(this, payload);
  }
}
