import { createReducer, on } from '@ngrx/store';

import { Candidate } from '@shared/models/candidate';
import { CandidateExperienceUser } from '@shared/models/candidate-experience-user';
import { Referral } from '@shared/models/referral';

import * as UserActions from './user.actions';

export const userFeatureKey = 'user';

export interface UserState {
  referrals: Referral[];
  jobApplications: number[];
  referredJobsIds: number[];
  loggedInCeUser: CandidateExperienceUser;
  loggedInCandidate: Candidate;
  compareApplicationsData: boolean;
}

export const initialState: UserState = {
  referrals: null,
  jobApplications: null,
  referredJobsIds: null,
  loggedInCeUser: null,
  loggedInCandidate: null,
  compareApplicationsData: false,
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.loadLocalReferralsSuccess, (state, {referrals}) => ({...state, referrals })),
  on(UserActions.loadReferredJobsSuccess, (state, {referredJobsIds}) => {
    const updatedReferredJobsIds = Array.isArray(state.referredJobsIds)
      ? [...state.referredJobsIds, ...referredJobsIds]
      : referredJobsIds;

    return {...state, referredJobsIds: updatedReferredJobsIds };
  }),
  on(UserActions.loadJobApplicationsSuccess, (state, {jobsIds}) => ({...state, jobApplications: jobsIds})),
  on(UserActions.loadReferralFromUrl, (state, {referral}) => {
    const updatedReferredJobsIds = Array.isArray(state.referredJobsIds)
      ? [...state.referredJobsIds, referral.jobId]
      : [referral.jobId];

    return {...state, referredJobsIds: updatedReferredJobsIds };
  }),
  on(UserActions.persistApplicationForJob, (state, {jobId}) => {
    const updatedJobApplications = Array.isArray(state.jobApplications)
      ? [...state.jobApplications, jobId]
      : [jobId];

    return {...state, jobApplications: updatedJobApplications };
  }),
  on(UserActions.loadLoggedInCeUserStateSuccess, (state, { ceUser }) => ({ ...state, loggedInCeUser: ceUser })),
  on(UserActions.loadLoggedInCandidateStateSuccess, (state, { candidate }) => ({ ...state, loggedInCandidate: candidate })),
  on(UserActions.compareApplicationsDataUpdate, (state, { value }) => ({ ...state, compareApplicationsData: value }))
);
