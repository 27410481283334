import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) { }

  canAccess(): Observable<boolean> {
    return this.authService.isAuthenticated$.pipe(
      map((authenticated) => !this.authService.preventNavigation && authenticated),
      first(),
    );
  }

  canActivate(route?: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canAccess();
  }

  canActivateChild(route?: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canAccess();
  }

  canLoad(route?: Route): Observable<boolean> {
    this.canAccess()
      .subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        this.authService.login();
      }
    });

    return this.canAccess();
  }
}
