import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { showModalAlert, confirmModal, resetModal } from '@shared/modal/modal.actions';
import { isModalConfirmed } from '@shared/modal/modal.selector';
import { ModalInfo } from '@shared/models/modal';

@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor(private readonly dialog: MatDialog, private readonly store: Store<{}>) {}

  private currentModal: MatDialogRef<any>;

  private events: Subscription[] = [];

  private data: ModalInfo;

  public createModal(component: ComponentType<any>, data?: ModalInfo): any {
    if (!this.currentModal) {
      this.currentModal = this.dialog.open(component, {
        disableClose: true,
        panelClass: data.panelClass,
        data: { ...data }
      });
      this.subscribeEvents();
      this.data = data;
    }
  }

  private subscribeEvents() {
    this.events.push(this.currentModal.backdropClick()
      .subscribe(() => {
        this.closeWithAlert();
      }));

    this.events.push(this.store.select(isModalConfirmed)
      .pipe(first(Boolean))
      .subscribe((isConfirmed) => {
        if(isConfirmed) {
          this.closeModal();
          this.store.dispatch(resetModal());
        }
      }));
  }

  public closeWithAlert() {
    if (!!this.currentModal) {
      this.showAlert(this.closeModal);
    }
  }

  public closeModal() {
    this.currentModal.close();
    this.events.forEach((event) => event.unsubscribe());
    this.events = [];
    this.currentModal = null;
  }

  private showAlert(closeCallback) {
    if(this.data.showAlert) {
      this.store.dispatch(showModalAlert({
        currentState: {
          title: 'Are you sure you want to leave?',
          message: 'All unsaved changes will be lost.',
          actions: [confirmModal({})]
        }
      }));
    }else {
      closeCallback();
    }
  }

}
