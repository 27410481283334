import {  NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MojoNavMenuModule } from 'mojo-nav-menu';

import { SharedModule } from '@shared/shared.module';

import { HeaderComponent } from './header.component';

export const layoutComponents = [
  HeaderComponent,
];

@NgModule({
  declarations: [...layoutComponents],
  exports: [...layoutComponents],
  imports: [
    SharedModule,
    MatIconModule,
    MojoNavMenuModule,
  ],
})
export class LayoutHeaderModule { }
