import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { ICON_CONFIG } from './icons.config';

@Injectable({ providedIn: 'root' })
export class IconService {
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    const iconBaseFullPath = ICON_CONFIG.iconsBasePath;

    ICON_CONFIG.icons
      .concat(ICON_CONFIG.logos)
      .concat(ICON_CONFIG.social)
      .concat(ICON_CONFIG.images)
      .forEach((item) => {
        const iconPath = `${iconBaseFullPath}${item}.svg`;
        iconRegistry.addSvgIcon(
          item,
          sanitizer.bypassSecurityTrustResourceUrl(iconPath)
        );
      });
  }
}
