import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  LicenseConfigurationState,
  licenseConfigurationStoreKey,
} from '@core/license-configuration/store/license-configuration.reducer';

export const getLicenseConfigurationState = createFeatureSelector<LicenseConfigurationState>(
  licenseConfigurationStoreKey
);

export const getLicenseConfigurationStateExtensions = createSelector(
  getLicenseConfigurationState,
  (state: LicenseConfigurationState) => state?.extensions,
);

export const checkIfLicenseContainsFeatureExtension = createSelector(
  getLicenseConfigurationStateExtensions,
  (state: string[], props?: { featureExtension: string }) => state ? state?.includes(props?.featureExtension) : undefined,
);
