import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AvatarActions } from 'mojo-avatar';
import { of } from 'rxjs';
import { map, switchMap, tap, mergeMap, catchError, withLatestFrom, concatMap } from 'rxjs/operators';

import { loadReferralSuccess } from '@collections/referrals/referrals.actions';
import { ProfileApiService } from '@core/api/profile-api.service';
import { ReferralApiService } from '@core/api/referral-api.service';
import { CoreConfig, CORE_CONFIG } from '@core/core.config';
import { getSelectedJobBoardId } from '@core/job-board/job-board.selectors';
import { LocalStorageService } from '@core/services/local-storage.service';
import { getLoggedInCandidate, getLoggedInCeUser } from '@core/user/user.selectors';
import { DangerAlert, SuccessAlert } from '@shared/alert';
import { ModalService } from '@shared/modal/moda.service';

import {
  updateProfile,
  updateProfileImageSuccess,
  loadUserStateFailure,
  loadJobApplications,
  loadLocalReferralsSuccess,
  loadLocalReferrals,
  loadReferralFromUrl,
  persistReferral,
  persistApplicationForJob,
  loadJobApplicationsSuccess,
  loadReferredJobsSuccess,
  loadLocalReferralsFailure,
  updateProfilePassword,
  updateProfilePasswordSuccess,
  updateProfilePasswordFailure,
  loadLoggedInCeUserState,
  loadLoggedInCeUserStateSuccess,
  loadLoggedInCandidateStateSuccess,
  loadLoggedInCandidateState,
} from './user.actions';

@Injectable()
export class UserEffects {

  loadReferralFromUrl$ = createEffect(() => this.actions$.pipe(
      ofType(loadReferralFromUrl),
      map(({referral}) => persistReferral({ referral })
      )
    ));

  persistReferral$ = createEffect(() => this.actions$.pipe(
    ofType(persistReferral),
    withLatestFrom(this.store.select(getSelectedJobBoardId)),
    tap(([{referral}, jobBoardId]) => {
      const key = this.generateLocalStorageKey(this.coreConfig.localStorageConfig.referralsKey, jobBoardId);
      this.localStorageService.addUniqueToArray(
        key,
        referral.id
      );
    })
  ),
  { dispatch: false});

  persistJobApplication$ = createEffect(() => this.actions$.pipe(
    ofType(persistApplicationForJob),
    withLatestFrom(this.store.select(getSelectedJobBoardId)),
    tap(([{jobId}, jobBoardId]) => {
      const key = this.generateLocalStorageKey(this.coreConfig.localStorageConfig.jobApplicationsKey, jobBoardId);
      this.localStorageService.addUniqueToArray(
        key,
        jobId
      );
    })
  ),
  { dispatch: false});

  loadLocalUserReferrals$ = createEffect(() => this.actions$.pipe(
    ofType(loadLocalReferrals),
    withLatestFrom(this.store.select(getSelectedJobBoardId)),
    switchMap(([_, jobBoardId]) => {
      const key = this.generateLocalStorageKey(this.coreConfig.localStorageConfig.referralsKey, jobBoardId);
      const referralIds = this.localStorageService.arraySafeLoad(key);

      return this.referralApiService.load(referralIds)
        .pipe(
          mergeMap((referrals) => [
            loadLocalReferralsSuccess({ referrals }),
            loadReferralSuccess({ referrals }),
          ]),
          catchError((error) => of(loadLocalReferralsFailure({ error })))
        );
      }
    )
  ));

  loadReferredJobs$ = createEffect(() => this.actions$.pipe(
    ofType(loadLocalReferralsSuccess),
    map(({referrals}) => {
      const referredJobsIds = referrals.map((ref) => ref.jobId);

      return loadReferredJobsSuccess({referredJobsIds});
    })
  ));

  loadJobApplications$ = createEffect(() => this.actions$.pipe(
    ofType(loadJobApplications),
    withLatestFrom(this.store.select(getSelectedJobBoardId)),
    map(([_, jobBoardId]) => {
      const key = this.generateLocalStorageKey(this.coreConfig.localStorageConfig.jobApplicationsKey, jobBoardId);
      const jobsIds = this.localStorageService.arraySafeLoad(key);

      return loadJobApplicationsSuccess({ jobsIds });
    })
  ));

  loadLoggedInCeUserState$ = createEffect(() => this.actions$.pipe(
    ofType(loadLoggedInCeUserState),
    withLatestFrom(this.store.select(getLoggedInCeUser)),
    switchMap(([_, profile]) => (!!profile
      ? of(loadLoggedInCeUserStateSuccess({ ceUser: profile }))
      : this.authExperienceApiService.profileCeUser()
        .pipe(
          mergeMap((ceUser) => {
            const actions = [];
            actions.push(loadLoggedInCeUserStateSuccess({ ceUser }));
            if (!!ceUser.candidateId) {
              actions.push(loadLoggedInCandidateState());
            }

            return actions;
          }),
          catchError((error) => of(loadUserStateFailure({ error })))))
    )));

  loadLoggedInCandidateState$ = createEffect(() => this.actions$.pipe(
    ofType(loadLoggedInCandidateState),
    withLatestFrom(this.store.select(getLoggedInCandidate)),
    switchMap(([_, profile]) => (!!profile
      ? of(loadLoggedInCandidateStateSuccess({ candidate: profile }))
      : this.authExperienceApiService.profile()
        .pipe(
          map((candidate) => loadLoggedInCandidateStateSuccess({ candidate })),
          catchError((error) => of(loadUserStateFailure({ error })))))
    )));

  loadLoggedInCeUserStateSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(loadLoggedInCeUserStateSuccess),
    tap(({ ceUser }) => {
      localStorage.setItem('candidate_id', ceUser.candidateId?.toString());
    })
  ), {dispatch: false});

  updateProfile$ = createEffect(() => this.actions$.pipe(
    ofType(updateProfile),
    switchMap(({ candidate, photo}) => {
      if (!!photo) {
        return this.authExperienceApiService.saveProfilePicture(photo)
          .pipe(
              map((attachment) => updateProfileImageSuccess({ candidate: { ...candidate, candidateImageId: attachment.id}}))
        );
      }

      return of(updateProfileImageSuccess({candidate}));
    })
  ));

  addProfileImage$ = createEffect(() => this.actions$.pipe(
    ofType(updateProfileImageSuccess),
    withLatestFrom(this.store.select(getLoggedInCeUser)),
    switchMap(([{ candidate }, ceUser]) => this.authExperienceApiService.updateProfile(candidate)
          .pipe(concatMap((updatedCandidate) => {
            this.modalService.closeModal();

            return [
              AvatarActions.avatarLoad({ avatar: { id: candidate.candidateImageId}}),
              loadLoggedInCandidateStateSuccess({candidate: { ...candidate, ...updatedCandidate }}),
              loadLoggedInCeUserStateSuccess({ ceUser: { ...ceUser,
                  firstName: updatedCandidate.firstName, lastName: updatedCandidate.lastName, email: updatedCandidate.emailId }}),
              new SuccessAlert('Profile updated with success')];
          }))
    )));

  updatePassword = createEffect( () => this.actions$.pipe(
    ofType(updateProfilePassword),
    switchMap( ({ password }) =>
       this.authExperienceApiService.changePassword(password)
        .pipe(
          map(updateProfilePasswordSuccess),
          catchError((error) => of(updateProfilePasswordFailure({ error })))
        )
    )
  ));

  updatePasswordSuccess = createEffect( () => this.actions$.pipe(
    ofType(updateProfilePasswordSuccess),
    map(() => new SuccessAlert('Password has been changed'))
  ));

  updatePasswordFailure = createEffect( () => this.actions$.pipe(
    ofType(updateProfilePasswordFailure),
    map(() => new DangerAlert('Please try again later'))
  ));

  private generateLocalStorageKey(key: string, jobBoardId: number): string {
    return `${key}-${jobBoardId}`;
  }

  constructor(
    private readonly actions$: Actions,
    private readonly referralApiService: ReferralApiService,
    private readonly store: Store,
    private readonly localStorageService: LocalStorageService,
    private readonly authExperienceApiService: ProfileApiService,
    private readonly modalService: ModalService,
    @Inject(CORE_CONFIG) public readonly coreConfig: CoreConfig,
  ) {}
}
