import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store, Action } from '@ngrx/store';
import { Observable } from 'rxjs';

import { getAlertState } from '@shared/modal/modal.selector';
import { ModalAlert} from '@shared/models/modal';

import { cancelModal } from '../../modal.actions';

@Component({
  selector: 'mojo-modal',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  modal$: Observable<ModalAlert> = this.store.pipe(select(getAlertState));

  constructor(private readonly store: Store<{}>, public activeModal: NgbActiveModal) {}

  cancel() {
    this.store.dispatch(cancelModal());
    this.close();
  }

  confirm(actions: Action[]) {
    actions.forEach((action) => this.store.dispatch(action));
    this.close();
  }

  close() {
    this.activeModal.close('Close click');
  }
}
