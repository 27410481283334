import { InjectionToken } from '@angular/core';

export const CORE_CONFIG = new InjectionToken<CoreConfig>('core-config');

interface LocalStorageConfig {
  /*
  * Key used for loading local referrals in user recognition feature
  * Referral is saved after URL with referral ID is opened
  */
  referralsKey: string;
  /*
  * Key used for loading local job applications in user recognition feature
  * Job Application is saved after submitting an application
  */
  jobApplicationsKey: string;
}

export interface CoreConfig {
  /**
   * Local storage keys
   */
  localStorageConfig: LocalStorageConfig;
}

export const coreConfig: CoreConfig = {
  localStorageConfig: {
    referralsKey: 'referralIds',
    jobApplicationsKey: 'appliedJobIds'
  },
};
