import { Action } from '@ngrx/store';

import { Pagination } from './pagination.model';

export enum PaginationActionTypes {
  Load = '[Pagination] Load Paginations',
  ChangePage = '[Pagination] Change page',
  Clear = '[Pagination] Clear page',
  Success = '[Pagination] Load Success',
  Fail = '[Pagination] Load Fail',
}

export class PaginationLoad implements Action {
  readonly type = PaginationActionTypes.Load;
  constructor(
    public page: Pagination
  ) { }
}

export class PaginationChangePage implements Action {
  readonly type = PaginationActionTypes.ChangePage;
  constructor(
    public pageNumber: number
  ) { }
}

export class PaginationClearPage implements Action {
  readonly type = PaginationActionTypes.Clear;
}

export type PaginationActionsUnion = PaginationLoad | PaginationChangePage;
