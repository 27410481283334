import { createAction, props } from '@ngrx/store';

import { JobBoardAttachmentTypeEnum } from '@core/job-board/job-board-attachment-type.enum';
import { JobBoardRaw } from '@shared/models/job-board';

export const jobBoardSelectBoard = createAction(
  '[Collections Job Board] Select Board',
  props<{ canonicalName: string }>()
);

export const jobBoardGetJobBoard = createAction(
  '[Collections Job Board] Get Job Board',
  props<{ canonicalName: string }>()
);

export const jobBoardGetJobBoardSuccess = createAction(
  '[Collections Job Board] Get Job Board Success',
  props<{ jobBoard: JobBoardRaw }>()
);

export const jobBoardGetJobBoardFail = createAction(
  '[Collections Job Board] Get Job Board Fail',
  props<{ error: any }>()
);

export const jobBoardGetJobBoardAttachment = createAction(
  '[Collections Job Board] Get Job Board Attachment',
  props<{ id: number; attachmentId: number; actionType: JobBoardAttachmentTypeEnum }>()
);

export const jobBoardGetJobBoardLogoSuccess = createAction(
  '[Collections Job Board] Get Job Board Logo Success',
  props<{ logoRoute: string }>()
);

export const jobBoardGetJobBoardHeaderSuccess = createAction(
  '[Collections Job Board] Get Job Board Header Success',
  props<{ headerRoute: string }>()
);
