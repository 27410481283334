import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { CypressSynchronizationService } from './cypress-synchronization.service';

@Injectable()
export class CypressHttpInterceptor {

  constructor(
    private readonly synchronisationService: CypressSynchronizationService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.synchronisationService.increaseOpenedRequests();

    return next.handle(request)
      .pipe(
        finalize(() => {
          this.synchronisationService.decreaseOpenedRequests();
        }),
      );
  }
}
