import { HttpClient } from '@angular/common/http';
import { Injectable, Optional, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Association } from '@shared/models/association';
import { Attachment } from '@shared/models/attachment';
import { Candidate } from '@shared/models/candidate';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PublicExperienceApiService extends ApiService<any> {
  constructor(
    http: HttpClient,
    @Inject(String) @Optional() url: string
  ) {
    super(http, `candidates/public/experience/${url || ''}`);
  }

  createCandidate(body: Partial<Candidate>): Observable<Candidate> {
    return this.post({ url: '', body });
  }

  patchCandidate(body: Partial<Candidate>): Observable<Candidate> {
    return this.patch({ url: `${body.id}`, body });
  }

  applyForJob(body: Association): Observable<Association> {
    return this.post({ url: `${body.candidateId}/apply-for-job/${body.reqId}` });
  }

  createAccount(candidateId: number, password: string, jobBoardName: string) {
    return this.post({ url: `${candidateId}/create-account?defaultJobBoard=${jobBoardName}`, body: password});
  }

  createAttachment(attachment: Partial<Attachment>, file: File, referredBy?: string): Observable<Attachment> {
    const formData = new FormData();
    formData.append('file', file);

    Object.keys(attachment)
      .forEach((key) => {
        formData.append(key, attachment[key]);
      });

    if (!!referredBy) {
      formData.append('referredBy', referredBy);
    }

    return this.http
      .post<Attachment>( `${this.baseUrl}${attachment.associateModuleId}/upload-resume`, formData)
      .pipe(catchError(this.handleError));
  }

  removeGhostCandidate(candidateId: number) {
    return this.patch({ url: `${candidateId}/remove-ghost`});
  }

  checkIfEmailNotExistInAuth0(email: string) {
    return this.get({ url: 'check-email', params: { email }});
  }

  getLatestResumePdfForUnauthorized(id: number) {
    return this.get({ url: `${id}/resume`});
  }

  getDefaultJobBoard() {
    return this.get({ url: 'default-job-board' });
  }
}
