import { createReducer, on } from '@ngrx/store';

import { licenseConfigurationGetExtensionsSuccess } from '@core/license-configuration/store/license-configuration.actions';


export const licenseConfigurationStoreKey = 'licenseConfiguration';

export interface LicenseConfigurationState {
  extensions: string[];
}

export const initialLicenseConfigurationState: LicenseConfigurationState = {
  extensions: null,
};

export const licenseConfigurationReducer = createReducer(
  initialLicenseConfigurationState,

  on(licenseConfigurationGetExtensionsSuccess, (state, {extensions}) =>
    ({ ...state, extensions })),
);
