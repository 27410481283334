import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';

import { LicenseConfigurationService } from '@core/api/license-configuration.service';

import {
  licenseConfigurationGetExtensions,
  licenseConfigurationGetExtensionsSuccess,
} from './license-configuration.actions';

@Injectable()
export class LicenseConfigurationEffects {

  loadLicenseExtension$ = createEffect( () =>
    this.actions$.pipe(
      ofType(licenseConfigurationGetExtensions),
      switchMap(() =>
        this.licenseConfigurationService.getLicenseExtension()
          .pipe(
            map((extensions) =>
                licenseConfigurationGetExtensionsSuccess({ extensions })
            )
          )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<{}>,
    private readonly licenseConfigurationService: LicenseConfigurationService,
  ) {
  }
}
