import { createAction, props } from '@ngrx/store';

export const licenseConfigurationGetExtensions = createAction(
  '[License Configuration] License Configuration Get Extensions',
);

export const licenseConfigurationGetExtensionsSuccess = createAction(
  '[License Configuration] License Configuration Get Extensions Success',
  props<{ extensions: string[] }>()
);
