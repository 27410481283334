import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mojo-terms-and-conditions-modal',
  templateUrl: './terms-and-conditions-modal.component.html',
  styleUrls: ['./terms-and-conditions-modal.component.scss']
})
export class TermsAndConditionsModalComponent {

  constructor(private readonly dialogRef: MatDialogRef<TermsAndConditionsModalComponent>) {
  }

  closeDialog(actionTriggered: boolean = false) {
    this.dialogRef.close(actionTriggered);
  }

}
