import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Referral } from '@shared/models/referral';

import * as ReferralsActions from './referrals.actions';

export interface ReferralsState extends EntityState<Referral> {
  selectedReferralId: number;
}

export const referralsAdapter: EntityAdapter<Referral> = createEntityAdapter<Referral>({});

export const initialState: ReferralsState = referralsAdapter.getInitialState({
  selectedReferralId: null,
});

const _referralsReducer = createReducer(
  initialState,
  on(ReferralsActions.createReferralSuccess, (state, { referral }) =>
    referralsAdapter.addOne(referral, state)),
  on(ReferralsActions.getReferralSuccess, (state, { referral }) =>
    referralsAdapter.upsertOne(referral, state)),
  on(ReferralsActions.loadReferralSuccess, (state, { referrals }) =>
    referralsAdapter.upsertMany(referrals, state)),
  on(ReferralsActions.selectReferral, (state, { referralId }) =>
    ({...state, selectedReferralId: referralId})),
);

export function referralsReducer(state: ReferralsState | undefined, action: Action) {
  return _referralsReducer(state, action);
}
