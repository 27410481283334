import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, retryWhen, switchMap, take } from 'rxjs/operators';

import { jobBoardGetJobBoard } from '@core/job-board/job-board.actions';
import { getJobBoardByCanonicalName } from '@core/job-board/job-board.selectors';
import { loadLoggedInCeUserState } from '@core/user/user.actions';
import { genericRetryStrategy, isTruthy } from '@shared/utils';

@Injectable({ providedIn: 'root' })
export class JobsBoardGuard implements CanActivate {
  constructor(
    private readonly store: Store<{}>,
    private readonly router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const canonicalName = route.params.jobBoardCanonicalName;

    if (!canonicalName) {
      this.router.navigateByUrl(this.router.parseUrl(route.data.jobBoardMissingRedirect));

      return false;
    }

    const ceUserId = localStorage.getItem('ce_user_id');
    if (!!ceUserId) {
      this.store.dispatch(loadLoggedInCeUserState());
    }

    return this.checkStore(canonicalName)
      .pipe(
        switchMap((isBoardInStore) => {
          if (isBoardInStore) {
            return of(true);
          }
          this.store.dispatch(jobBoardGetJobBoard({ canonicalName }));

          return this.checkStore(canonicalName)
            .pipe(
              map((retryBoardIsInStore) => {
                if (!!retryBoardIsInStore) {
                  return retryBoardIsInStore;
                } else {
                  throw new Error('Something went wrong');;
                }
              }),
              retryWhen(genericRetryStrategy({ maxRetryAttempts: 2, scalingDuration: 500 })),
            );
        }),
        catchError(() => of(false)),
        map((validJobBoard: boolean) => {
          if (!validJobBoard) {
            if (state.url.startsWith(route.data.jobBoardMissingRedirect)) {
              this.router.navigateByUrl(route.data.notFoundRedirect);

              return false;
            } else {
              this.router.navigateByUrl(route.data.jobBoardMissingRedirect);

              return false;
            }
          }

          return true;
        }),
        take(1),
      );
  }

  private checkStore(canonicalName: string): Observable<boolean> {
    return this.store.select(getJobBoardByCanonicalName, canonicalName)
      .pipe(
        map(isTruthy),
      );
  }
}
