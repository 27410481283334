import { createFeatureSelector, createSelector } from '@ngrx/store';

import { getJobsEntities } from '@collections/jobs/';
import { Candidate } from '@shared/models/candidate';
import { Pagination, PaginationAdapter } from '@shared/pagination';
import { isEmpty } from '@shared/utils';

import {
  JobsPageState,
  JOBS_PAGE_TOKEN,
} from './jobs-page.reducer';
import { JobsPageDetailsState } from './reducers/jobs-page-details.reducer';

export const getJobsPageState = createFeatureSelector<JobsPageState>(
  JOBS_PAGE_TOKEN
);

export const getJobsPage = createSelector(
  getJobsPageState,
  (state: JobsPageState) => state?.jobs
);

export const getJobsPageIsLoaded = createSelector(
  getJobsPage,
  (pagination: Pagination) => !isEmpty(pagination)
);

export const getJobsPageNumber = createSelector(
  getJobsPage,
  (pagination: Pagination) => pagination?.number
);

export const getJobsPageJobsIds = createSelector(
  getJobsPage,
  (pagination: Pagination = {}) => new PaginationAdapter(pagination).contentIds
);

export const getJobsPageJobs = createSelector(
  getJobsPageJobsIds,
  getJobsEntities,
  (ids, entities) =>
    !!ids && !!entities && ids.map((id: number) => entities[id])
);

export const getJobsPageDetails = createSelector(
  getJobsPageState,
  (state: JobsPageState) => state?.details
);

export const getScore = createSelector(
  getJobsPageState,
  (state: JobsPageState) => state?.details.resumeSent?.applyScores
);

export const isResumeUploadedFromMainPage = createSelector(
  getJobsPageDetails,
  (state: JobsPageDetailsState) => state?.resumeSent?.resumeSentFromMain
);

export const getNumberOfMatchesFromMain = createSelector(
  getJobsPageDetails,
  (state: JobsPageDetailsState) => state?.resumeSent?.numberOfMatches
);

export const getJobsPageSelectedId = createSelector(
  getJobsPageDetails,
  (state: JobsPageDetailsState) => state?.selectedId
);

export const getJobsPageParsing = createSelector(
  getJobsPageDetails,
  (state: JobsPageDetailsState) => state?.parsing
);

export const getResumeWhichWentToParsing = createSelector(
  getJobsPageDetails,
  (state: JobsPageDetailsState) => state?.parsing.attachment
);

export const getJobsPageParsingCandidateId = createSelector(
  getJobsPageDetails,
  (state: JobsPageDetailsState) => state?.parsing.candidateId
);

export const getJobsPageParsingCandidate = createSelector(
  getJobsPageDetails,
  (state: JobsPageDetailsState) => state?.parsing.candidate
);

export const getJobBoardJobsMatchesForCandidate = createSelector(
  getJobsPageDetails,
  (state: JobsPageDetailsState) => state?.parsing.matches
);

export const getJobsPageParsingStatus = createSelector(
  getJobsPageDetails,
  (state: JobsPageDetailsState) => state?.parsing.status
);

export const getJobsPageCandidateName = createSelector(
  getJobsPageParsingCandidate,
  (state: Candidate) => state?.firstName
);

export const getJobsSelected = createSelector(
  getJobsEntities,
  getJobsPageSelectedId,
  (entities, selectedId) => !!selectedId && !!entities && entities[selectedId]
    ? entities[selectedId]
    : null
);

export const getJobPageFilters = createSelector(
  getJobsPageState,
  (state) => state?.filter
);

export const isFiltersApplied = createSelector(
  getJobPageFilters,
  (filters) => !!filters && (!!filters.titles.length || !!filters.locations.length || !!filters.departments.length)
);

export const getCities = createSelector(
  getJobsPageState,
  (state: JobsPageState) => state?.cities?.ready && state?.cities?.boardCities
);

export const getDepartments = createSelector(
  getJobsPageState,
  (state: JobsPageState) => state?.departments?.ready && state?.departments?.boardDepartments
);

export const getJobsPageConfettiStatus = createSelector(
  getJobsPageDetails,
  (state: JobsPageDetailsState) => state?.confettiStatus
);

export const getIfJobIsAssociatedForSpecificCandidate = createSelector(
  getJobsPageState,
  (state) => state?.isJobAssociated
);

export const checkIfPageShouldBeReloaded = createSelector(
  getJobsPageState,
  isResumeUploadedFromMainPage,
  (state, isResumeSentFromMain) =>  state.noMatchesFlag || (isResumeSentFromMain !== null && !isResumeSentFromMain)
);
