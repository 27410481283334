import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { jobBoardsReducer, JobBoardsState } from '@collections/job-boards/job-boards.reducer';

import { JobsState, jobsReducer } from './jobs/jobs.reducer';
import { ReferralsState, referralsReducer } from './referrals/referrals.reducer';

export const COLLECTIONS_TOKEN = 'collections';

export interface CollectionsState {
  jobs: JobsState;
  referrals: ReferralsState;
  jobBoards: JobBoardsState;
}

export const reducers: ActionReducerMap<CollectionsState> = {
  jobs: jobsReducer,
  referrals: referralsReducer,
  jobBoards: jobBoardsReducer,
};

export const getCollectionsState = createFeatureSelector<CollectionsState>(
  COLLECTIONS_TOKEN
);
