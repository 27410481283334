import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  AlertActionsUnion,
  AlertActionTypes,
  AlertTypes,
} from './alert.actions';

export interface AlertState {
  type: AlertTypes;
  message: string;
}

export const initialState: AlertState = {
  type: AlertTypes.NONE,
  message: '',
};

export function alertReducer(
  state = initialState,
  action: AlertActionsUnion
): AlertState {
  switch (action.type) {
    case AlertActionTypes.SUCCESS:
      return {
        ...state,
        type: AlertTypes.SUCCESS,
        message: action.message,
      };
    case AlertActionTypes.INFO:
      return {
        ...state,
        type: AlertTypes.INFO,
        message: action.message,
      };
    case AlertActionTypes.WARNING:
      return {
        ...state,
        type: AlertTypes.WARNING,
        message: action.message,
      };
    case AlertActionTypes.DANGER:
      return {
        ...state,
        type: AlertTypes.DANGER,
        message: action.message,
      };
    case AlertActionTypes.PRIMARY:
      return {
        ...state,
        type: AlertTypes.PRIMARY,
        message: action.message,
      };
    case AlertActionTypes.SECONDARY:
      return {
        ...state,
        type: AlertTypes.SECONDARY,
        message: action.message,
      };
    case AlertActionTypes.LIGHT:
      return {
        ...state,
        type: AlertTypes.LIGHT,
        message: action.message,
      };
    case AlertActionTypes.DARK:
      return {
        ...state,
        type: AlertTypes.DARK,
        message: action.message,
      };
    case AlertActionTypes.NONE:
      return {
        ...state,
        type: AlertTypes.NONE,
      };
    default:
      return state;
  }
}

export const getAlert = createFeatureSelector<AlertState>('snackbar');

export const getAlertMessage = createSelector(
  getAlert,
  (state: AlertState) => !!state && state.message
);
export const getAlertType = createSelector(
  getAlert,
  (state: AlertState) => !!state && state.type
);
