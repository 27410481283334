import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { AppFeatureExtensions } from '@app/app-feature-extensions.config';
import { jobBoardsGetJobBoards } from '@collections/job-boards/job-boards.actions';
import { checkIfLicenseContainsFeatureExtension } from '@core/license-configuration/store/license-configuration.selectors';
import { getLoggedInCandidate } from '@core/user/user.selectors';
import {
  loadExperienceBulbDates,
  loadLastLoginDateFromLocalStorage,
  profilePageLoadCandidateAssociations,
} from '@modules/profile-page/store/profile-page.actions';
import { isTruthy } from '@shared';

@Injectable({ providedIn: 'root' })
export class ProfilePageResolver implements Resolve<any> {

  constructor(
    private readonly store: Store<{}>,
    private readonly router: Router,
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    this.store.dispatch(jobBoardsGetJobBoards());
    this.store.dispatch(profilePageLoadCandidateAssociations({ candidateId: localStorage.getItem('candidate_id'), offset: 0 }));
    this.store.dispatch(loadExperienceBulbDates());
    this.store.dispatch(loadLastLoginDateFromLocalStorage());

    return this.store.select(getLoggedInCandidate)
      .pipe(filter(isTruthy), first());
  }

  canLoad(): Observable<boolean> {
    return this.store.select(checkIfLicenseContainsFeatureExtension, { featureExtension: AppFeatureExtensions.REFERRAL_EXTENDED })
      .pipe(
        filter((isReferralExtended) => isReferralExtended !== undefined),
        first(),
        map((isReferralExtended) => {
          if (isReferralExtended) {
            this.router.navigate(['/404']);
          }

          return !isReferralExtended;
        })
      );
  }
}
