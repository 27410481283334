import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@material/material.module';

import { LocationLabelComponent } from './location-label.component';

const components = [
  LocationLabelComponent,
];

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    CommonModule,
    MaterialModule,
  ],
  exports: [
    ...components,
  ]
})
export class LocationLabelModule { }
