import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormHeaderComponent } from './form-header.component';

@NgModule({
  declarations: [FormHeaderComponent],
  exports: [FormHeaderComponent],
  imports: [
    CommonModule,
  ]
})
export class FormHeaderModule {}
