export enum SocialInfoType {
  LINKEDIN = 'linkedin',
  TWITTER = 'twitter',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  STACK_OVERFLOW = 'stackoverflow',
  GITHUB = 'github',
  CRUNCHBASE = 'crunchbase',
  YOUTUBE = 'youtube',
}

export class SocialInfo {
  id?: number;
  type: SocialInfoType;
  url: string;
}

export function setType(socialInfo: SocialInfo): SocialInfo {
  if (!!socialInfo.url) {
    socialInfo.type = null;

    Object.keys(SocialInfoType)
      .forEach((socialInfoTypeKey: string) => {
        const socialType = SocialInfoType[socialInfoTypeKey];
        if (socialInfo?.url?.includes(socialType)) {
          socialInfo.type = socialType as SocialInfoType;
        }
      });

    if (!socialInfo.type) {
      socialInfo.type = 'website' as SocialInfoType;
    }
  }

  return socialInfo;
}
