import { Action } from '@ngrx/store';

export enum AlertTypes {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LIGHT = 'light',
  DARK = 'dark',
  NONE = 'none',
}

export enum AlertActionTypes {
  SUCCESS = '[Alert] Success alert',
  INFO = '[Alert] Info alert',
  WARNING = '[Alert] Warning alert',
  DANGER = '[Alert] Danger alert',
  PRIMARY = '[Alert] Primary alert',
  SECONDARY = '[Alert] Secondary alert',
  LIGHT = '[Alert] Light alert',
  DARK = '[Alert] Dark alert',
  NONE = '[Alert] None',
}

export class SuccessAlert implements Action {
  readonly type = AlertActionTypes.SUCCESS;
  constructor(
    public message: string
  ) { }
}

export class InfoAlert implements Action {
  readonly type = AlertActionTypes.INFO;
  constructor(
    public message: string
  ) { }
}

export class WarningAlert implements Action {
  readonly type = AlertActionTypes.WARNING;
  constructor(
    public message: string
  ) { }
}

export class DangerAlert implements Action {
  readonly type = AlertActionTypes.DANGER;
  constructor(
    public message: string
  ) { }
}

export class PrimaryAlert implements Action {
  readonly type = AlertActionTypes.PRIMARY;
  constructor(
    public message: string
  ) { }
}

export class SecondaryAlert implements Action {
  readonly type = AlertActionTypes.SECONDARY;
  constructor(
    public message: string
  ) { }
}

export class LightAlert implements Action {
  readonly type = AlertActionTypes.LIGHT;
  constructor(
    public message: string
  ) { }
}

export class DarkAlert implements Action {
  readonly type = AlertActionTypes.DARK;
  constructor(
    public message: string
  ) { }
}

export class NoneAlert implements Action {
  readonly type = AlertActionTypes.NONE;
}

export type AlertActionsUnion =
  | SuccessAlert
  | InfoAlert
  | WarningAlert
  | DangerAlert
  | PrimaryAlert
  | SecondaryAlert
  | LightAlert
  | DarkAlert
  | NoneAlert;
