import { environment } from '@env/environment';

const hostname = (): string => location.hostname;

const pathname = (): string => location.pathname;

// MOJAT-3571
const replaceOpenWithItalent = (tenant: string): string => tenant.toLowerCase() === 'open' ||
          isLocal()
          ? environment.DEFAULT_DEV_TENANT
          : tenant;

export const jobBoardNameFromPath = (): string => pathname()
    .toString()
    .split('/')[1];

export const tenantNameFromPath = (): string => replaceOpenWithItalent(hostname()
    .split('.')[0]);

export const toTenantUrl = (url: string): string => url.replace(/TENANT/, tenantNameFromPath());

export const isLocal = (): boolean => hostname() === 'localhost' || hostname() === '127.0.0.1';
