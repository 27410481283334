import { HttpClient } from '@angular/common/http';
import { Inject, Optional, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiService } from '@core/api/api.service';
import { Association } from '@shared/models/association';
import { Attachment} from '@shared/models/attachment';
import { Candidate } from '@shared/models/candidate';
import { CandidateExperienceUser } from '@shared/models/candidate-experience-user';

@Injectable({ providedIn: 'root' })
export class ProfileApiService extends ApiService<any> {
  constructor(
    http: HttpClient,
    @Inject(String) @Optional() url: string
  ) {
    super(http, `candidates/experience/${url || ''}`);
  }

  profile(): Observable<Candidate> {
    return this.get({ url: 'profile' });
  }

  profileCeUser(): Observable<CandidateExperienceUser> {
    return this.get({ url: 'profile/ce-user' });
  }

  updateProfile(candidate: Candidate): Observable<Candidate> {
    const defaultBoard = localStorage.getItem('redirect');

    return this.patch({ url: `profile?defaultJobBoard=${defaultBoard}`, body: candidate});
  }

  applyForJobAsAuthorized(body: Association): Observable<Association> {
    return this.post({ url: `profile/${body.candidateId}/apply-for-job/${body.reqId}` });
  }

  download(): Observable<Blob> {
    return this.get({ url: 'profile/picture', responseType: 'blob' });
  }

  saveProfilePicture(file: File): Observable<Attachment> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http
      .put<Attachment>(`${this.baseUrl}profile/picture`, formData)
      .pipe(catchError(this.handleError));
  }

  saveResume(file: File): Observable<Attachment> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http
      .post<Attachment>(`${this.baseUrl}profile/resume`, formData)
      .pipe(catchError(this.handleError));
  }

  changePassword(password: string): Observable<any> {
    return this.patch({ url: `profile/change-password`, body: password});
  }

  checkIfJobIsAssociatedForSpecificCandidate(jobId: number): Observable<boolean> {
    return this.get({ url: `profile/association/${jobId}`});
  }

  getLatestResume(): Observable<Attachment> {
    return this.get({ url: 'profile/resume' });
  }

  downloadLatestResume(): Observable<Blob> {
    return this.get({ url: 'profile/resume/download', responseType: 'blob' });
  }

  convert(file: File, format='pdf') {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('format', format);

    return this.http
      .post(`${this.baseUrl}attachment/convert`, formData, { responseType: 'blob'})
      .pipe(catchError(this.handleError));
  }

  getLastLoginDate(): Observable<number> {
    return this.get({ url: 'profile/last-login-date' });
  }
}
