import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AlertTypes, NoneAlert } from './alert.actions';
import { AlertState, getAlert } from './alert.reducer';

@Component({
  selector: 'mojo-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  alert$: Observable<AlertState> = this.store.select(getAlert)
    .pipe(
      tap(({ type }: AlertState) => type !== AlertTypes.NONE && this.show())
    );

  alertTypes = AlertTypes;

  constructor(
    private readonly store: Store<{}>
  ) { }

  show() {
    setTimeout(() => this.store.dispatch(new NoneAlert()), 6000);
  }

  close() {
    this.store.dispatch(new NoneAlert());
  }
}
