import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CommonMojoPhoneNumberValidator } from 'mojo-phone-input';
import { Observable, Subscription } from 'rxjs';
import { first, map, throttleTime } from 'rxjs/operators';

import * as CandidatesActions from '@collections/candidates/candidates.actions';
import { ProfileApiService } from '@core/api/profile-api.service';
import { PublicExperienceApiService } from '@core/api/public-experience-api.service';
import { AuthService } from '@core/auth';
import { tenantNameFromPath } from '@core/auth/path.utils';
import { getSelectedJobBoardCanonicalName } from '@core/job-board/job-board.selectors';
import { jobsPageUploadFileTooBigFail } from '@modules/jobs/store/jobs-page.actions';
import { Job } from '@shared/models';
import { ApplyModuleObject } from '@shared/models/apply-module-object';
import { Candidate } from '@shared/models/candidate';
import { SocialInfoType } from '@shared/models/social-info';
import { TermsAndConditionsModalComponent } from '@shared/terms-and-conditions-modal/terms-and-conditions-modal.component';
import { LinkedInRegex, warningPercentage } from '@shared/utils';

import { Referral } from '../models/referral';
import { SharedHeaderObject } from './shared-header-object';


@Component({
  selector: 'mojo-shared-apply-form',
  templateUrl: './shared-apply-form.component.html',
  styleUrls: [ './shared-apply-form.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedApplyFormComponent implements OnInit {
  @Input() job: Job;
  @Input() referral: Referral;
  @Input() candidate: Candidate;
  @Input() formHeader: string;
  @Input() resumeRequired = false;
  @Input() sharedHeaderObject: SharedHeaderObject;
  @Input() cancelButtonEnabled = false;
  @Input() isAuthenticated = false;

  canonicalName$: Observable<string> = this.store.select(getSelectedJobBoardCanonicalName);
  latestResume$;

  oneHundredMaxLength = 100;
  mobileLength = 20;
  linkedInIdLength = 255;
  resumeDeleted = false;
  notSubmitted = true;

  candidateId: number;

  warningLength = warningPercentage;


  form: FormGroup;

  genders: string[] = [ 'Male', 'Female', 'Other', `Decline to Self Identify` ];
  races: string[] = [ 'Asian', 'Hispanic/Latino', 'African American/Black', 'American Indian/Alaska Native',
    'Native Hawaiian/Other Pacific Islander', 'Two or More Races', 'White/Caucasian', 'Decline to Self Identify' ];
  veteranStatuses: string[] = [ 'I am a US veteran', 'I am not a veteran',  'Decline to Self Identify' ];

  subs = new Subscription();
  referralId: number;
  private readonly MAX_FILE_SIZE_MB = 50;

  constructor(
    private readonly store: Store<{}>,
    private readonly profileApiService: ProfileApiService,
    private readonly http: HttpClient,
    private readonly publicApiService: PublicExperienceApiService,
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      resumeFile: this.resumeRequired ? new FormControl(null, Validators.required) : new FormControl(null),
      firstName: new FormControl(null, [ Validators.required, Validators.maxLength(this.oneHundredMaxLength) ]),
      lastName: new FormControl(null, [ Validators.required, Validators.maxLength(this.oneHundredMaxLength) ]),
      emailId: new FormControl(null,
        [ Validators.required, Validators.email ],
        [this.emailExistsValidator()]
      ),
      mobile: new FormControl(null,
        [Validators.required, CommonMojoPhoneNumberValidator()]),
      linkedInId: new FormControl(null, [Validators.maxLength(this.linkedInIdLength), Validators.pattern(LinkedInRegex)]),
      gender: new FormControl(null, Validators.required),
      race: new FormControl(null, Validators.required),
      veteranStatus: new FormControl(null, Validators.required),
      locationInput: new FormControl(null),
      termsAndConditions: this.isItalentTenant() ? new FormControl(false, Validators.requiredTrue) : new FormControl(),
    });
    if (this.isAuthenticated) {
      this.latestResume$ = this.profileApiService.getLatestResume();
      this.form.controls.emailId.disable();
    }
    if (this.referral) {
      this.updateReferralCandidateFields(this.referral);
    } else if(this.candidate) {
      this.updateCandidateFields(this.candidate);
    }
  }

  submitJobApplication(jobId: number, canonicalName: string) {
    this.notSubmitted = false;
    if(this.resumeRequired && this.isFileTooBig(this.form.value.resumeFile.size)) {
      this.store.dispatch(
        jobsPageUploadFileTooBigFail({ maxFileSize: this.MAX_FILE_SIZE_MB })
      );
      this.form.reset();
    } else {
      const applyModuleObject: ApplyModuleObject = {
        ...this.form.value,
        referralId: this.referralId,
        referredBy: `${this.referral?.referralFirstName} ${this.referral?.referralLastName}`,
        jobId,
        id: this.candidateId,
        jobBoardName: canonicalName
      };
      this.fillEmailId(applyModuleObject);
      this.store.dispatch(CandidatesActions.upsertCandidateByApplyModule({ applyModuleObject }));
    }
  }

  submitGeneralApplication() {
    this.notSubmitted = false;
    const candidate = this.getCandidate();
    this.store.dispatch(CandidatesActions.patchCandidate({ candidate, resumeFile: this.form.value.resumeFile }));
  }

  getCandidate() {
    const candidate: Candidate = {
      id: this.candidateId,
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      emailId: this.form.value.emailId,
      mobile: this.form.value.mobile,
      socialInfo: this.resolveSocialInfo(),
      gender: this.form.value.gender,
      referralId: this.referralId,
      address: {
        city: this.form.value.locationInput.name,
        country: this.form.value.locationInput.countryName,
        state: this.form.value.locationInput.stateCode,
      },
    };

    return candidate;
  }

  isFileTooBig(fileSize: number): boolean {
    const fileSizeInMB = fileSize/1024/1024;

    return fileSizeInMB > this.MAX_FILE_SIZE_MB;
  }

  private resolveSocialInfo() {
    return !!this.form.value.linkedInId ? [{type: SocialInfoType.LINKEDIN, url: this.form.value.linkedInId}] : [];
  }

  private updateReferralCandidateFields(referral: Referral) {
    this.referralId = referral.id;

    this.form.patchValue({
      firstName: referral.candidateFirstName,
      lastName: referral.candidateLastName,
      emailId: referral.candidateEmail,
      mobile: referral.candidatePhoneNumber,
      linkedInId: referral.candidateLinkedin,
    });
  }

  private updateCandidateFields(candidate: Candidate) {
    this.referralId = candidate.referralId;
    this.candidateId = candidate.id;


    const socialLink = !!candidate.socialInfo
      ? candidate.socialInfo
        .find(({ type }) => type === SocialInfoType.LINKEDIN)
      : null;

    this.form.patchValue({
      firstName: candidate.firstName,
      lastName: candidate.lastName,
      emailId: candidate.emailId,
      mobile: candidate.mobile,
      linkedInId: !!socialLink
          ? socialLink.url
          : null,
      locationInput: !!candidate.address ? {
        ...candidate.address,
        name: candidate.address.city,
        countryCode: candidate.address.country,
        stateCode: candidate.address.state,
        countryName: candidate.address.country
      } : []
    });
  }

  deleteResume() {
    this.resumeRequired = true;
    this.resumeDeleted = true;

    this.form.get('resumeFile')
      .setValidators(Validators.required);
    this.form.get('resumeFile')
      .updateValueAndValidity();
  }

  showResume(path) {
    this.http.get(path, { responseType: 'blob'})
      .subscribe((res: any) => {
        const file = new Blob([res], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
    });
  }

  openTermsAndConditions() {
    this.dialog.open(
      TermsAndConditionsModalComponent,
      {
        panelClass: 'large-size-modal',
      }
    );
  }

  redirectToLoginPage() {
    this.authService.logout(true);
  }

  private emailExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl) => this.publicApiService.checkIfEmailNotExistInAuth0(control.value).pipe(
      map((emailIsAvailable) => emailIsAvailable ?  null : { emailAlreadyExists: true })
    ).pipe(
        throttleTime(250),
        first(),
    )
  }

  private fillEmailId(applyModuleObject: ApplyModuleObject) {
    if (this.isAuthenticated) {
      if (this.referral) {
        applyModuleObject.emailId = this.referral.candidateEmail;
      } else if (this.candidate) {
        applyModuleObject.emailId = this.candidate.emailId;
      }
    }
  }

  /** TODO remove this hard-coded tenant name use feature extension */
  isItalentTenant() {
    return tenantNameFromPath() === 'italent';
  }
}
