import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FormConnectDirective } from '@shared/modal/components/form/form-connect.directive';
import { FormModalComponent } from '@shared/modal/components/form/form.modal.component';

import { AlertComponent } from './components/alert/alert.component';
import { ModalEffects } from './modal.effects';
import { modalReducer } from './modal.reducer';

@NgModule({
  imports: [
    CommonModule,
    NgbModalModule,
    StoreModule.forFeature('modal', modalReducer),
    EffectsModule.forFeature([ModalEffects]),
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatDividerModule,
  ],
  declarations: [
    AlertComponent,
    FormModalComponent,
    FormConnectDirective,
  ],
  exports: [
    AlertComponent,
    FormModalComponent,
    FormConnectDirective,
  ],
  providers: [ModalEffects],
})
export class ModalModule {}
