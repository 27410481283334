import { createAction, props } from '@ngrx/store';

import { ApplyModuleObject } from '@shared/models/apply-module-object';
import { Candidate } from '@shared/models/candidate';
import { TokenDto } from '@shared/models/token-dto';

export const upsertCandidateByApplyModule = createAction(
  '[Candidates] Upsert Candidates By Apply Module',
  props<{ applyModuleObject: ApplyModuleObject }>()
);

export const upsertCandidateByApplyModuleSuccess = createAction(
  '[Candidates] Upsert Candidates By Apply Module Success',
  props<{ applyModuleObject: ApplyModuleObject }>()
);

export const createCandidateFail = createAction(
  '[Candidates] Create Candidates Fail',
  props<{ error: any }>()
);

export const createCandidateFileTooBig = createAction(
  '[Candidates] Create Candidates File Too Big',
  props<{ maxFileSize: number }>()
);

export const saveJobApplicationInfoLocally = createAction(
  '[Candidates] Save Job Application Info Locally',
  props<{ jobId: number }>()
);

export const patchCandidate = createAction(
  '[Candidates] Patch Candidate',
  props<{ candidate: Candidate; resumeFile: File }>()
);

export const patchCandidateSuccess = createAction(
  '[Candidates] Patch Candidate Success',
  props<{ patchedCandidate: Candidate }>()
);

export const patchCandidateFail = createAction(
  '[Candidates] Patch Candidate Fail',
  props<{ error: any }>()
);

export const createCandidatePassword = createAction(
  '[Candidates] Create Candidate Password',
  props<{ candidateId: number; password: string }>()
);

export const createCandidatePasswordSuccess = createAction(
  '[Candidates] Create Candidate Password Success',
  props<{ tokenDto: TokenDto }>()
);

export const createCandidatePasswordFail = createAction(
  '[Candidates] Create Candidate Password Fail',
  props<{ error: any }>()
);

export const loadLastLoginDate = createAction(
  '[Candidates] Load Last Login Date'
);

export const loadLastLoginDateSuccess = createAction(
  '[Candidates] Load Last Login Date Success',
  props<{ lastLoginDate: Date }>()
);
