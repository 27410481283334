import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LicenseConfigurationEffects } from './store/license-configuration.effects';
import { licenseConfigurationReducer, licenseConfigurationStoreKey } from './store/license-configuration.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(
      licenseConfigurationStoreKey,
      licenseConfigurationReducer,
    ),
    EffectsModule.forFeature([LicenseConfigurationEffects]),
  ],
})
export class LicenseConfigurationModule {}
