import { createAction, props } from '@ngrx/store';

import { Candidate } from '@shared/models/candidate';
import { CandidateExperienceUser } from '@shared/models/candidate-experience-user';
import { Referral } from '@shared/models/referral';

export const persistReferral = createAction(
  '[User] Persist Referral',
  props<{ referral: Referral }>()
);

export const persistApplicationForJob = createAction(
  '[User] Persist Application For Job',
  props<{ jobId: number }>()
);

export const loadReferralFromUrl = createAction(
  '[User] Load Referral From URL',
  props<{ referral: Referral}>()
);

export const loadLocalReferrals = createAction(
  '[User] Load Local Referrals'
);

export const loadLocalReferralsSuccess = createAction(
  '[User] Load Local Referrals Success',
  props<{ referrals: Referral[] }>()
);

export const loadLocalReferralsFailure = createAction(
  '[User] Load Local Referrals Failure',
  props<{ error: any }>()
);

export const loadReferredJobsSuccess = createAction(
  '[User] Load Referred Jobs Success',
  props<{ referredJobsIds: number[] }>()
);

export const loadJobApplications = createAction(
  '[User] Load JobApplications'
);

export const loadJobApplicationsSuccess = createAction(
  '[User] Load JobApplications Success',
  props<{ jobsIds: number[] }>()
);

export const loadUserStateFailure = createAction(
  '[User] Load User State Failure',
  props<{ error: any }>()
);

export const loadLoggedInCeUserState = createAction(
  '[User] Load Logged In CE User State'
);

export const loadLoggedInCeUserStateSuccess = createAction(
  '[User] Load Logged In CE User State Success',
  props<{ ceUser: CandidateExperienceUser }>()
);

export const loadLoggedInCandidateState = createAction(
  '[User] Load Logged In Candidate State'
);

export const loadLoggedInCandidateStateSuccess = createAction(
  '[User] Load Logged In Candidate State Success',
  props<{ candidate: Candidate }>()
);


export const updateProfile = createAction(
  '[Profile Page] Update Profile State',
  props<{ candidate: Candidate; photo?: any }>()
);

export const updateProfileImageSuccess = createAction(
  '[Profile Page] Update Profile Success',
  props<{ candidate: Candidate }>()
);

export const updateProfilePassword = createAction(
  '[Profile Page] Update Profile Password',
  props<{ password: string }>()
);

export const updateProfilePasswordSuccess = createAction(
  '[Profile Page] Update Profile Password Success',
);

export const updateProfilePasswordFailure = createAction(
  '[Profile Page] Update Profile Password Failure',
  props<{ error: any }>()
);

export const compareApplicationsDataUpdate = createAction(
  '[Profile Page] Compare Applications Data Update',
  props<{ value: boolean }>()
);
