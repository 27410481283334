import { Component, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core';

import { Location } from '@shared/models/location';

@Component({
  selector: 'mojo-location-label',
  templateUrl: './location-label.component.html',
  styleUrls: ['./location-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationLabelComponent implements OnChanges {
  @Input() locations: Location[];

  @Input() showMultiple = false;

  defaultLocation: Location;
  locationTooltip: string;

  ngOnChanges() {
    this.locationTooltip = this.locations.filter(Boolean)
      .map(this.formatLocation)
      .join('');
    this.defaultLocation = this.locations && this.locations.length > 0 ? this.locations[0] : null;
  }

  formatLocation(location: Location) {
    if (!!location.city || !!location.countryName) {

      return [location.city, location.state, location.countryName]
        .filter((v) => !!v && !v.match(/^\d+$/))
        .map((v) => v.replace(/ /g, '\u00a0'))
        .join(', ') + '\n';
    }

    return !!location.location ? location.location + '\n' : '';
  }
}
