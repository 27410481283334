import { createAction, props } from '@ngrx/store';

import { JobBoard } from '@shared/models';

export const jobBoardsGetJobBoards = createAction(
  '[Collections Job Boards] Get Job Boards',
);

export const jobBoardsGetJobBoardsSuccess = createAction(
  '[Collections Job Boards] Get Job Boards Success',
  props<{ jobBoards: JobBoard[] }>()
);

export const jobBoardsGetDefaultJobBoard = createAction(
  '[Collections Job Boards] Get Default Job Board',
);

export const jobBoardsGetDefaultJobBoardSuccess = createAction(
  '[Collections Job Boards] Get Default Job Board Success',
  props<{ defaultJobBoard: string }>()
);
