import { createReducer, on } from '@ngrx/store';

import * as WebsocketActions from './websocket.actions';

export enum State {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED'
}
export interface WebsocketState {
  state: State;
}

export const initialState: WebsocketState = {
  state: State.DISCONNECTED
};

export const websocketReducer = createReducer(
  initialState,
  on(WebsocketActions.websocketConnected, () => ({
    state: State.CONNECTED
  })),
  on(WebsocketActions.websocketDisconnected, () => ({
    state: State.DISCONNECTED
  })),
);
