import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@core/api';
import { Location } from '@shared/models';

@Injectable()
export class GeolocationService extends ApiService<Location> {
  constructor(http: HttpClient) {
    super(http, 'geolocation/locations');
  }

  autocomplete(name: string): Observable<Location[]> {
    return this.get({ params: { name } });
  }
}
