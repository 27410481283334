import { Component, Input } from '@angular/core';

import { PageLayoutEnum } from './page-layout.enum';

@Component({
  selector: 'mojo-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
})
export class PageLayoutComponent {
  @Input() extendedPaddingSpace = false;
  @Input() selectedLayoutType = PageLayoutEnum.RIGHT_SECTION;
  layoutTypes = PageLayoutEnum;
}
