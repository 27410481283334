import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';

import { AlertComponent } from './alert.component';
import { alertReducer } from './alert.reducer';

@NgModule({
  imports: [
    CommonModule,
    NgbAlertModule,
    StoreModule.forFeature('snackbar', alertReducer),
  ],
  declarations: [AlertComponent],
  exports: [AlertComponent],
})
export class AlertModule {}
