import { createAction, props } from '@ngrx/store';

import { ApplyModuleObject } from '@shared/models/apply-module-object';
import { Attachment } from '@shared/models/attachment';

export const createAttachment = createAction(
  '[Attachments] Create Attachment',
  props<{ attachment: Attachment; resume: File }>()
);

export const createAttachmentSuccess = createAction(
  '[Attachments] Create Attachment Success',
  props<{ attachment: Attachment }>()
);

export const createAttachmentFail = createAction(
  '[Attachments] Create Attachment Fail',
  props<{ error: any }>()
);

export const uploadApplyModuleResume = createAction(
  '[Attachments] Upload Apply Module Resume',
  props<{ applyModuleObject: ApplyModuleObject }>()
);

export const uploadApplyModuleResumeSuccess = createAction(
  '[Attachments] Upload Apply Module Resume Success',
  props<{ applyModuleObject: ApplyModuleObject }>()
);

export const uploadApplyModuleResumeFail = createAction(
  '[Attachments] Upload Apply Module Resume Fail',
  props<{ error: any }>()
);
