import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { CypressHttpInterceptor } from './cypress-http.interceptor';
import { CypressSynchronizationService } from './cypress-synchronization.service';
import { CypressEffects } from './cypress.effects';

/**
 * Module used to communicate application state to Cypress testing environment.
 * @see CypressEffects
 */
@NgModule({
  imports: [
    EffectsModule.forFeature([CypressEffects]),
  ],
})
export class CypressModule {
  static forRoot(): ModuleWithProviders<CypressModule> {
    return {
      ngModule: CypressModule,
      providers: [
        CypressSynchronizationService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CypressHttpInterceptor,
          multi: true,
        },
      ],
    };
  }
}
