import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { format } from 'date-fns';

import { isNumber, toInteger } from '../utils';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value
        .trim()
        .split('/');

      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { month: toInteger(dateParts[0]), day: null, year: null };
      } else if (
        dateParts.length === 2 &&
        isNumber(dateParts[0]) &&
        isNumber(dateParts[1])
      ) {
        return {
          month: toInteger(dateParts[0]),
          day: toInteger(dateParts[1]),
          year: null,
        };
      } else if (
        dateParts.length === 3 &&
        isNumber(dateParts[0]) &&
        isNumber(dateParts[1]) &&
        isNumber(dateParts[2])
      ) {
        return {
          month: toInteger(dateParts[0]),
          day: toInteger(dateParts[1]),
          year: toInteger(dateParts[2]),
        };
      }
    }

    return null;
  }

  format(date: NgbDateStruct): string {
    return date
      ? format(new Date(date.year, date.month - 1, date.day), 'MM/dd/yyyy')
      : '';
  }
}
