import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import {JobBoardEffects, jobBoardReducer, jobBoardFeatureKey} from '@core/job-board';

import { AssociationsEffects } from './associations';
import { AttachmentsEffects } from './attachments';
import { CandidatesEffects } from './candidates';
import { COLLECTIONS_TOKEN, reducers } from './collections.reducer';
import { JobBoardsEffects } from './job-boards/job-boards.effects';
import { JobsEffects } from './jobs/';
import { ReferralsEffects } from './referrals';

export const effects = [
  JobsEffects,
  ReferralsEffects,
  JobBoardEffects,
  CandidatesEffects,
  AttachmentsEffects,
  AssociationsEffects,
  JobBoardsEffects,
];

@NgModule({
  imports: [
    StoreModule.forFeature(COLLECTIONS_TOKEN, reducers),
    StoreModule.forFeature(jobBoardFeatureKey, jobBoardReducer),
    EffectsModule.forFeature([...effects]),
  ],
  providers: [...effects],
})
export class CollectionsModule { }
