import { HttpClient } from '@angular/common/http';
import { Injectable, Optional, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { Eeoc } from '@shared/models/eeoc';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class EeocApiService extends ApiService<Eeoc> {
  constructor(
    http: HttpClient,
    @Inject(String) @Optional() url: string
  ) {
    super(http, `associations/${url || ''}`);
  }

  createEeoc(associationId: number, body: Eeoc): Observable<Eeoc> {
    return this.post({ url: `${associationId}/eeoc`, body });
  }
}
