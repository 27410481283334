import { NgModule } from '@angular/core';
import { Store, StoreModule } from '@ngrx/store';
import { MojoWebsocketModule, MojoWebsocketService } from 'mojo-websocket';

import { AuthService } from '@core/auth';
import { toTenantUrl } from '@core/auth/path.utils';
import { environment } from '@env/environment';

import { websocketConnected, websocketReducer } from './store';
@NgModule({
  imports: [
    StoreModule.forFeature('websocket', websocketReducer),
    MojoWebsocketModule.forRoot({ baseUrl: toTenantUrl(environment.BASE_URI) }),
  ],
})
export class WebsocketModule {
  constructor(
    webSocketService: MojoWebsocketService,
    store: Store<{}>,
    authService: AuthService,
  ) {

    webSocketService
      .start(() => 'ce', authService.getIdentity)
      .subscribe(() => {
        store.dispatch(websocketConnected());
      });
  }
}
