import {
  Action,
  ActionReducerMap,
  createReducer,
  on,
} from '@ngrx/store';
import { Tag } from 'mojo-tag';

import { retrieve } from '@shared';
import { Pagination, paginationReducer } from '@shared/pagination';

import * as JobsPageActions from './jobs-page.actions';
import { jobsPageDetailsReducer, JobsPageDetailsState } from './reducers/jobs-page-details.reducer';

export const JOBS_PAGE_TOKEN = 'jobsPage';
export const JobsFilterKey = 'jobsPageFilter';

export interface JobPageFilters {
  titles?: (string | Tag)[];
  locations?: string[];
  departments?: string[];
  ids?: number[];
}

export interface JobsPageState {
  jobs: Pagination;
  details: JobsPageDetailsState;
  filter: JobPageFilters;
  cities: JobPageCities;
  departments: JobPageDepartments;
  isJobAssociated: IsJobAssociated;
  noMatchesFlag: boolean;
}

export const initialJobPageFilters: JobPageFilters = {
  titles: [],
  locations: [],
  departments: [],
  ids: [],
};

export const initialIsJobAssociated: IsJobAssociated = {
  isJobAssociatedValue: null,
  isJobAssociatedValueLoaded: null,
};

export interface IsJobAssociated {
  isJobAssociatedValue: boolean;
  isJobAssociatedValueLoaded: boolean;
}

export interface JobPageCities {
  boardCities: string[];
  ready: boolean;
}

export const initialCities: JobPageCities = {
  boardCities: [],
  ready: false,
};

export interface JobPageDepartments {
  boardDepartments: string[];
  ready: boolean;
}

export const initialDepartments: JobPageDepartments = {
  boardDepartments: [],
  ready: false,
};

export const jobsPageReducers: ActionReducerMap<JobsPageState, Action> = {
  jobs: paginationReducer({load: JobsPageActions.jobsLoadJobsPage.type, success: JobsPageActions.jobsLoadJobsPageSuccess.type}),
  details: jobsPageDetailsReducer,
  filter: createReducer(
    retrieve(JobsFilterKey, initialJobPageFilters),
    on(
      JobsPageActions.jobsPageFiltersSet,
      JobsPageActions.resetFiltersWithoutDispatch,
      (state, { payload }) => ({
      ...state,
      ...payload,
    }))
  ),
  cities: createReducer(
    initialCities,
    on(JobsPageActions.jobsLoadCitesSuccess, (_, { cities }) => ({
      boardCities: [...cities],
      ready: true,
    })),
    on(JobsPageActions.jobsLoadCites, (state) => ({
      ...state,
      ready: false,
    }))
  ),
  departments: createReducer(
    initialDepartments,
    on(JobsPageActions.jobsLoadDepartmentsSuccess, (_, { departments }) => ({
      boardDepartments: [...departments],
      ready: true,
    })),
    on(JobsPageActions.jobsLoadDepartments, (state) => ({
      ...state,
      ready: false,
    }))
  ),
  isJobAssociated: createReducer(
    initialIsJobAssociated,
    on(JobsPageActions.isJobAssociatedWithLoggedCandidateSuccess, (state, { isJobAssociated }) => ({
        ...state,
        isJobAssociatedValue: isJobAssociated,
        isJobAssociatedValueLoaded: true,
      })
    ),
    on(JobsPageActions.isJobAssociatedWithLoggedCandidate, (state) => ({
        ...state,
        isJobAssociatedValueLoaded: false,
      })
    )
  ),
  noMatchesFlag: createReducer(
    null,
    on(JobsPageActions.setNoMatchesFlag, () => true
    ),
  )
};
