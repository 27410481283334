import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MojoCardModule } from 'mojo-card';

import { MaterialModule } from '@material/material.module';

import { ExpandableCardComponent } from './expandable-card.component';

@NgModule({
  imports: [CommonModule, MaterialModule, MojoCardModule],
  declarations: [ExpandableCardComponent],
  exports: [ExpandableCardComponent],
})
export class ExpandableCardModule {}
