import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';

import { getCollectionsState } from '@app/collections';
import { getSelectedJobBoardId } from '@core/job-board/job-board.selectors';
import { UserActions, UserSelectors } from '@core/user';

@Injectable({
  providedIn: 'root'
})
export class LocalStateLoaderGuard implements CanActivate {

  constructor(
    private readonly store: Store,
  ) {
  }

  canActivate(): Observable<boolean> {
    return this.checkStore()
      .pipe(
        switchMap(() => of(true)),
      );
  }

  private checkStore(): any {
    return combineLatest([
      this.store.select(UserSelectors.getUserJobApplications),
      this.store.select(UserSelectors.getUserReferrals),
      this.store.select(getSelectedJobBoardId),
      this.isCollectionsReady(),
    ])
    .pipe(
      filter(([_, __, jobBoardId]) => jobBoardId != null),
      tap(([applications, referrals]) => {
        if(!applications) {
          this.store.dispatch(UserActions.loadJobApplications());
        }
        if(!referrals) {
          this.store.dispatch(UserActions.loadLocalReferrals());
        }
      }),
      map(([applications, referrals]) => applications && referrals),
      filter((loaded) => !!loaded)
    );
  }

  private isCollectionsReady() {
    return this.store
    .select(getCollectionsState)
    .pipe(
      filter((v) => !!v),
      first(),
    );
  }

}
