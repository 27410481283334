import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { JobBoardStoreActions } from '@core/job-board';
import { getJobBoardByCanonicalName } from '@core/job-board/job-board.selectors';
import { JobBoard } from '@shared/models/job-board';

@Injectable({ providedIn: 'root' })
export class JobsBoardResolver implements Resolve<JobBoard> {

  constructor(
    private readonly store: Store<{}>,
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    const canonicalName = route.params.jobBoardCanonicalName;

    this.store.dispatch(JobBoardStoreActions.jobBoardSelectBoard({ canonicalName }));

    return this.store.select(getJobBoardByCanonicalName, canonicalName)
      .pipe(
        take(1),
      );
  }
}
