import { createAction, props } from '@ngrx/store';

import { ApplyModuleObject } from '@shared/models/apply-module-object';
import { Association } from '@shared/models/association';
import { Eeoc } from '@shared/models/eeoc';

export const createApplyAssociation = createAction(
  '[Associations] Create Association',
  props<{ association: Association; applyModuleObject: ApplyModuleObject }>()
);

export const createAssociationSuccess = createAction(
  '[Associations] Create Association Success',
  props<{ association: Association }>()
);

export const createAssociationFail = createAction(
  '[Associations] Create Association Fail',
  props<{ error: any }>()
);

export const createAssociationEeoc = createAction(
  '[Associations] Create Association Eeoc',
  props<{ associationId: number; eeoc: Eeoc }>()
);

export const createAssociationEeocSuccess = createAction(
  '[Associations] Create Association Eeoc Success',
  props<{ eeoc: Eeoc }>()
);

export const createAssociationEeocFail = createAction(
  '[Associations] Create Association Eeoc Fail',
  props<{ error: any }>()
);
